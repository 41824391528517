import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import SmallWinner from './SmallWinner';


export default function ResultHeader({ result, project }) {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col xs={24} sm={24} md={6} lg={6} xl={6}>
        <h1>{result.title}</h1>
        <p className='center'>
          {
            result.banner_url ?
              <img style={{width: "100%", maxWidth: '360px'}} src={result.banner_url} alt={result.title} />
              :
              <img style={{width: "100%", maxWidth: '360px'}} src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='200'%3E%3Crect width='400' height='20' fill='white'/%3E%3C/svg%3E" alt={result.title} />
          }
        </p>
      </Col>
      <Col xs={24} sm={24} md={18} lg={18} xl={18}>
        <h1 className="left">{result.campaign.title}</h1>
        {
          result.prize_type == 'bingo'  &&
            <p>
              {result.result_numbers.map((number, index) => (
                <span className="special_bingo_ball" key={index}>{number}</span>
              ))}
            </p>
        }
        <div>
          <h4 className="purple">Ganhadores</h4>
          {result.winners.map(winner => <SmallWinner winner={winner} project={project} />)}
        </div>
      </Col>
    </Row>
  );
}