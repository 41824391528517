import React from 'react';
import { Space, Row, Col } from 'antd';

export default function Terms({terms}) {

  return (
    <div className="smaller_container" style={{padding: '50px 35px'}}>
      <Space direction="vertical">
        <h1>Termos de Uso da Nacional Cap</h1>
        <div dangerouslySetInnerHTML={{ __html: terms.body }} />
      </Space>
    </div>
  )
}