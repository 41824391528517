import React, { useState, useEffect } from 'react';
import { Flex, Button } from 'antd';
import { Link } from 'react-router-dom';
import SmallCampaign from '../../../components/SmallCampaign'

export default function CampaignHomeIndex({campaigns, project}) {
  if (!campaigns || campaigns.length === 0) {
    return null;
  }

  return (
    <div id="home_campaigns" className="container">
      <h1>Outros sorteios</h1>
      <Flex justify="space-evenly" wrap="wrap" align="center" gap="medium" >
        {
          campaigns.map(campaign => (
            <SmallCampaign key={campaign.id} campaign={campaign} project={project} />
          ))
        }
      </Flex>
      <p style={{marginTop: 40}} className="center">
        <Link to={`/${project.name}/site/campanhas`}>
          <Button shape="round" size="small" type="default">Ver mais</Button>
        </Link>
      </p>
    </div>
  );
}