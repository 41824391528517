import React, { useState, useEffect, useRef } from 'react';
import { UserOutlined, LockOutlined, MobileOutlined, ContactsOutlined, HomeOutlined, MailOutlined } from '@ant-design/icons';
import MaskedInput from '../../../components/MaskedInput';
import { Input, Button, Form, Select } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';

export default function Profile({currentUser, project}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const numberRef = useRef();
  const [changeEmail, setChangeEmail] = useState(false);
  const [changePhone, setChangePhone] = useState(false);
  const [cities, setCities] = useState([]);

  function update_profile(formData) {
    document.activeElement.blur();
    setLoading(true);

    if (!changeEmail)
      delete formData.email;
    if (!changePhone)
      delete formData.phone;
    
    axios.put('/customers', { customer: { ...formData, current_password: '********' } })
      .then(function(response){
        window.location.href = `/${project.name}/site?flash_notice=Sua conta foi atualizada.`
      })
      .catch(function(error){
        toast.error('Erro ao processar solicitação.');
        if (error.response.status == 422){
          setErrors(error.response.data.errors);
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  function handleStateChange(value) {
    form.setFieldsValue({ city: undefined });
    loadCities(value);
  }

  function loadCities(value) {
    setCities([]);
    axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`)
      .then(function(response){
        setCities(response.data);
      })
      .catch(function(error){
        console.log(error);
      });
  }

  function zipCodeSearch(e) {
    const zipcode = e.target.value.replace(/[^0-9]/g, '');

    if (zipcode.length == 8) {
      axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
        .then(function(response){
          form.setFieldsValue(
            {
              address: response.data.logradouro, neighborhood: response.data.bairro, city: response.data.localidade, state: response.data.uf
            }
          );
          numberRef.current.focus();
        })
        .catch(function(error){
          console.log(error);
          setErrors({zipcode: ['CEP não encontrado']});
        });
    }
  }

  function handleChangeEmail() {
    setChangeEmail(true);
    form.setFieldsValue({ email: '' });
  }

  function handleChangePhone() {
    setChangePhone(true);
    form.setFieldsValue({ phone: '' });
  }

  useEffect(() => {
    form.setFieldsValue(
      {
        cpf: currentUser.cpf, masked_name: currentUser.masked_name, social_name: currentUser.social_name,
        city: currentUser.city, state: currentUser.state, email: currentUser.email
        // , email: currentUser.email, phone: currentUser.phone, zipcode: currentUser.zipcode, address: currentUser.address,
        // address_number: currentUser.address_number, address_complement: currentUser.address_complement, neighborhood: currentUser.neighborhood,
      }
    );
    if (currentUser.state)
      loadCities(currentUser.state);
  }, []);

  return (
    <div className="smaller_container">
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} form={form} layout="horizontal" name="profile" className="profile-form" initialValues={{ remember: true }} onFinish={update_profile}>
        <Form.Item name="cpf" rules={[{ required: true }]} value={currentUser.cpf} label="CPF" required>
          <MaskedInput name="phone" form={form} disabled={true} prefix={<UserOutlined className="site-form-item-icon" />}  mask="999.999.999-99" placeholder="000.000.000-00" />
        </Form.Item>
        <Form.Item name="masked_name" rules={[{ required: true }]} required label="Nome">
          <Input disabled={true} prefix={<ContactsOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item name="email" label="E-mail" hasFeedback={!!errors['email']} validateStatus={errors['email'] && 'error'} help={errors['email']?.join(', ')}>
          <Input disabled={!changeEmail} prefix={<ContactsOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <div style={{float: 'right', marginTop: '-27px'}} onClick={handleChangeEmail}><a>Alterar e-mail</a></div>
        <Form.Item name="phone" rules={changePhone && [{ required: true }]} required label="Telefone" hasFeedback={!!errors['phone']} validateStatus={errors['phone'] && 'error'} help={errors['phone']?.join(', ')}>
          <MaskedInput disabled={!changePhone} name="phone" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<MobileOutlined className="site-form-item-icon" />} mask={"(99) 99999 9999"} placeholder={currentUser.phone} />
        </Form.Item>
        <div style={{float: 'right', marginTop: '-27px'}} onClick={handleChangePhone}><a>Alterar telefone</a></div>
        <Form.Item name="social_name" label="Nome Social" hasFeedback={!!errors['social_name']} validateStatus={errors['social_name'] && 'error'} help={errors['social_name']?.join(', ')}>
          <Input prefix={<ContactsOutlined className="site-form-item-icon" />} />
        </Form.Item>
        {/* <Form.Item name="phone" rules={[{ required: true }]} required label="Telefone" hasFeedback={!!errors['phone']} validateStatus={errors['phone'] && 'error'} help={errors['phone']?.join(', ')}>
          <MaskedInput name="phone" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<MobileOutlined className="site-form-item-icon" />} type="phone" mask="(99) 99999 9999" placeholder="(99) 99999 9999" />
        </Form.Item> */}
        {/* <Form.Item name="email" type="email" label="E-mail" hasFeedback={!!errors['email']} validateStatus={errors['email'] && 'error'} help={errors['email']?.join(', ')}>
          <Input prefix={<MailOutlined className="site-form-item-icon" />} />
        </Form.Item> */}
        {/* <Form.Item name="zipcode" onBlur={zipCodeSearch} label="CEP" hasFeedback={!!errors['zipcode']} validateStatus={errors['zipcode'] && 'error'} help={errors['zipcode']?.join(', ')}>
          <MaskedInput name="zipcode" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<MobileOutlined className="site-form-item-icon" />} type="phone" mask="99999-999" placeholder="99999-999" />
        </Form.Item>
        <Form.Item name="address" label="Endereço" hasFeedback={!!errors['address']} validateStatus={errors['address'] && 'error'} help={errors['address']?.join(', ')}>
          <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item name="address_number" label="Número" hasFeedback={!!errors['address_number']} validateStatus={errors['address_number'] && 'error'} help={errors['address_number']?.join(', ')}>
          <Input ref={numberRef} prefix={<HomeOutlined className="site-form-item-icon" />} />
        </Form.Item>
        <Form.Item name="address_complement" label="Complemento" hasFeedback={!!errors['address_complement']} validateStatus={errors['address_complement'] && 'error'} help={errors['address_complement']?.join(', ')}>
          <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
        </Form.Item> */}
        {/* <Form.Item name="neighborhood" label="Bairro" hasFeedback={!!errors['neighborhood']} validateStatus={errors['neighborhood'] && 'error'} help={errors['neighborhood']?.join(', ')}>
          <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
        </Form.Item> */}
          <Form.Item name="state" rules={[{ required: true }]} required label="Estado" hasFeedback={!!errors['state']} validateStatus={errors['state'] && 'error'} help={errors['state']?.join(', ')}>
            <Select
              placeholder="UF"
              onChange={handleStateChange}
              showSearch
              optionFilterProp="label"
              allowClear
              options={[
                { value: 'AC', label: 'AC' },
                { value: 'AL', label: 'AL' },
                { value: 'AP', label: 'AP' },
                { value: 'AM', label: 'AM' },
                { value: 'BA', label: 'BA' },
                { value: 'CE', label: 'CE' },
                { value: 'ES', label: 'ES' },
                { value: 'GO', label: 'GO' },
                { value: 'MA', label: 'MA' },
                { value: 'MT', label: 'MT' },
                { value: 'MS', label: 'MS' },
                { value: 'MG', label: 'MG' },
                { value: 'PA', label: 'PA' },
                { value: 'PB', label: 'PB' },
                { value: 'PR', label: 'PR' },
                { value: 'PE', label: 'PE' },
                { value: 'PI', label: 'PI' },
                { value: 'RJ', label: 'RJ' },
                { value: 'RN', label: 'RN' },
                { value: 'RS', label: 'RS' },
                { value: 'RO', label: 'RO' },
                { value: 'RR', label: 'RR' },
                { value: 'SC', label: 'SC' },
                { value: 'SP', label: 'SP' },
                { value: 'SE', label: 'SE' },
                { value: 'TO', label: 'TO' },
                { value: 'DF', label: 'DF' }
              ]}
            >
            </Select>
          </Form.Item>
          <Form.Item name="city" rules={[{ required: true }]} required label="Cidade" hasFeedback={!!errors['city']} validateStatus={errors['city'] && 'error'} help={errors['city']?.join(', ')}>
            <Select
              disabled={cities.length == 0}
              placeholder="Cidade"
              allowClear
              showSearch
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())
              }
              loading={cities.length == 0}
              optionFilterProp="label"
              options={cities.map(city => ({ value: city.nome, label: city.nome }))}
            >
            </Select>
          </Form.Item>
        <Form.Item className="center">
          <Button shape="round" type="primary" htmlType="submit" loading={loading} className="login-form-button">
            Atualizar perfil
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}