import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import CartItem from './CartItem';
import Swal from 'sweetalert2';


const boxStyle = {
  border: '1px solid #ccc',
  padding: '15px',
  marginBottom: '10px',
  borderRadius: '15px',
};

export default function CartItems({cart, setCart}) {
  
  function removeItem(index) {
    axios.post(`/api/int/v1/shopping_carts/remove_item`, { index: index })
      .then(function(res){
        setCart(res.data);
        toast.success('Item removido do carrinho');
      })
      .catch(function(error){
        toast.error('Erro ao remover item');
      });
  }

  function emptyCart() {
    Swal.fire({
      title: 'Deseja limpar o carrinho?',
      showCancelButton: true,
      confirmButtonText: 'Limpar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post('/api/int/v1/shopping_carts/empty')
          .then(function(res){
            setCart({items: []});
          })
          .catch(function(error){
            toast.error('Erro ao limpar carrinho');
          });
      }
    });
  }

  return (
    <div>
      <h1 style={{fontSize: '150%', textAlign: 'left'}}>
        <Button disabled={cart.items?.length == 0} type="link" onClick={emptyCart} icon={<DeleteFilled style={{color: 'red'}} />} style={{float: 'right', color: 'red', padding: 0, height: 'unset'}}>
          Limpar Carrinho
        </Button>
        Itens do carrinho
      </h1>
      <div style={boxStyle}>
        {
          cart.items && cart.items.map((item, index) => {
            return (
              <CartItem item={item} removeItem={removeItem} index={index} key={index} />
            );
          })
        }
        {
          cart.items && cart.items.length === 0 &&
          <div>
            <h2>Carrinho vazio</h2>
          </div>
        }
      </div>
    </div>
  );
}