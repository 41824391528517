import React, { useEffect } from "react";
import { Input } from "antd";
import Inputmask from "inputmask";

export default function MaskedInput(props) {
  const { mask, id, form, name } = props;

  if (!form || !name) { throw new Error("Form and name options are required."); }
  
  useEffect(() => {
    Inputmask({
      mask: mask,
      jitMasking: true
    }).mask(id);
  }, []);

  function handleBlur(e) {
    form.setFieldValue(name, e.target.value);
  }

  function handleKeyUp(e) {
    if (e.key === "Enter") {
      form.submit();
      e.preventDefault();
    }
  }

  return (
    <Input id={id} {...props} onBlur={handleBlur} onKeyUp={handleKeyUp} />
  );
};