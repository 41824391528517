import React, { useState, useEffect } from 'react';
import { Button, Input, Flex, Space } from 'antd';
import { lpad } from '../single-page/utils/index.js';
import TicketsIcon from '../images/tickets.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { numberAsCurrency } from '../single-page/utils/index.js';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CartContext } from '../single-page/App.js';
import { CampaignContext } from '../single-page/App.js';
import CampaignFastBuyControls from './CampaignFastBuyControls.js';
import BlueTicketsIcon from '../images/tickets-blue.svg';
import moment from 'moment';
import Swal from 'sweetalert2';
import Cookie from 'js-cookie';

const inputStyle = {
  background: '#f7eb19',
  border: '1px solid #F6EB1C',
  marginLeft: '59px',
  marginRight: '59px',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: 1.5714285714285714,
  height: '42px',
  padding: '9px 15px',
  borderRadius: '10px',
  color: '#2F058B',
};

const buttonStyle = {
  background: '#f7eb19',
  border: '1px solid #F6EB1C',
  color: '#303483',
};

const buyButtonStyle = {
  width: '80%',
  textAlign: 'left',
  margin: '0 auto',
  display: 'block',
  fontWeight: 'bold',
}

const smallButtonStyle = {
  height: '42px',
  width: '42px',
}

export default function CampaignFastBuy({ campaign, currentUser, project }) {
  if (!campaign) return null;

  const start_time = moment(campaign.start_time);
  if (!campaign['open?']) {
    if (start_time.isAfter(moment())) {
      return (
        <div className="center">
          <h1>
            As vendas desta campanha começam às {start_time.format('HH:mm')} do dia {start_time.format('DD/MM/YYYY')}.
          </h1>
          <p>
            Fique de olho nas nossas redes sociais para as novidades!
          </p>
        </div>
      );
    } else {
      return (
        <div className="center">
          <h1>
            {/* As vendas desta campanha já foram encerradas. */}
            Prepare-se! Uma nova campanha de prêmios incríveis está a caminho!
          </h1>
          <p>
            Fique de olho nas nossas redes sociais para não perder a próxima campanha!
          </p>
        </div>
      );
    }    
  }
  const [quantity, setQuantity] = useState(campaign?.default_ticket_selection || 1);
  const [loading, setLoading] = useState(false);
  const [firstClick, setFirstClick] = useState(true);
  const { cartCount, setCartCount } = React.useContext(CartContext);
  const history = useHistory();

  function numberButtonCallback(number) {
    if (firstClick) {
      safeSetQuantity(parseInt(number.replace('*', '')))
    } else {
      safeSetQuantity(quantity + parseInt(number.replace('*', '')))
    }
    setFirstClick(false);
  }

  function safeSetQuantity(number) {
    if (number < 1) {
      setQuantity(1);
    } else if (number > campaign?.max_amount) {
      setQuantity(campaign?.max_amount);
    } else if (number < campaign?.min_amount) {
      setQuantity(campaign?.min_amount);
    } else {
      setQuantity(number);
    }
  }

  function buyTickets() {
    if (quantity == 0)
      return;

    buyTicketsCall();
  }

  async function tryToIncreaseQuantity() {
    let new_quantity = quantity;
    if (campaign?.max_amount && new_quantity >= campaign?.max_amount) {
      return quantity ;
    }
    if (new_quantity == 1)
      new_quantity = 3;
    if (new_quantity < 4)
      new_quantity = 5;
    else
      new_quantity = Math.ceil((new_quantity + 1) / 10) * 10;
    
    const response = await Swal.fire({
      title: 'Aumente suas chances de ganhar!',
      html: 
        `<p style="font-size: 110%">Você escolheu ${quantity} títulos por ${numberAsCurrency(campaign.ticket_price * quantity)}.\
         <br/>Que tal levar ${new_quantity} títulos por apenas ${numberAsCurrency(campaign.ticket_price * new_quantity)}?<p>`,
      showCancelButton: true,
      confirmButtonText: 'Sim, quero!',
      imageUrl: BlueTicketsIcon,
      imageWidth: 200,
      imageHeight: 100,
      confirmButtonColor: '#253875',
      cancelButtonText: `Não, adicionar apenas ${quantity}`,
      reverseButtons: true,
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    if (response.isConfirmed) {
      return new_quantity;
    } else {
      return quantity;
    }
  }

  async function buyCall(quantity) {
    setLoading(true);
    axios.post(`/api/int/v1/shopping_carts/add_next_items`, { campaign_id: campaign.id, amount: quantity })
      .then(response => {
        setCartCount(response.data.items.length);
        if (currentUser == null) {
          Cookie.set('go_to_purchase', 'true', { path: '/', expires: 1 });
          return history.push(`/${project.name}/site/cpf`);
        } else {
          axios.post('/api/int/v1/purchase_orders')
            .then(function(res){
              setCartCount(0);
              history.push(`/${project.name}/site/pedido/${res.data.id}`);
            })
            .catch(function(error){
              if (error.response?.status == 422 && error?.response?.data?.error == 'unconfirmed_phone') {
                Cookie.set('go_to_purchase', 'true', { path: '/', expires: 1 });
                history.push(`/${project.name}/site/confirmar-telefone`);
              } else {
                console.log("Erro ao criar pedido de compra, indo pra o carrinho");
                console.log(error);
                history.push(`/${project.name}/site/carrinho`);
              }
            });
        }
      })
      .catch(error => {
        if (error.response?.status == 422 && error?.response?.data?.error == 'sold_out') {
          toast.error('Ops, parece que acabaram os títulos disponíveis para essa campanha. ');
        } else if (error.response?.status == 422 && error?.response?.data?.error == 'cart_full_error') {
          toast.error('Valor máximo de títulos no carrinho atingido');  
        } else if (error.response?.status == 422 && error?.response?.data?.error == 'closed') {
          toast.error('Ops, parece que as vendas para essa campanha foram encerradas.');
        } else {
          toast.error('Erro ao processar compra, tente em instantes');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <CampaignFastBuyControls campaign={campaign} buyCall={buyCall} loading={loading} project={project} />
    </>
  );
}