import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FaqCollapse from '../../components/FaqCollapse';

export default function Faq() {
  const [frequently_asked_questions, setFrequentlyAskedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get('/api/int/v1/frequently_asked_questions')
      .then(function(res){
        setFrequentlyAskedQuestions(res.data);
      })
      .catch(function(error){
        console.log(error);
      })
      .then(function(){
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <h1>
        Ficou alguma dúvida?
        <br/>
        <small>Estamos aqui para ajudar</small>
      </h1>
      <div className="container">
        <FaqCollapse frequently_asked_questions={frequently_asked_questions} />
      </div>
    </div>
  );
}
