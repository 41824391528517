import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Carousel, Space, Button, Flex } from 'antd';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { CartContext } from '../App.js';
import ItemToChoose from '../../components/ItemToChoose';
import Swal from 'sweetalert2';

export default function CampaignChoose({project, isMobile}) {
  let { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cartCount, setCartCount } = React.useContext(CartContext);
  
  useEffect(() => {
    loadItems();
  }, []);

  function loadItems() {
    axios.get(`/api/int/v1/campaigns/${campaignId}/next_items`)
    .then(response => {
      if (response.data.campaign.bingo) setItems(response.data.numbers);
      if (!campaign) setCampaign(response.data.campaign);
    })
    .catch(error => {
      if (error.response.status == 422 && error.response.data.error == 'sold_out') {
        toast.error('Não há mais títulos disponíveis!');
      } else {
        toast.error('Erro ao carregar bilhetes');
      }
      setTimeout(() => {
        window.location.href = `/${project.name}`;
      }, 3000);
    })
    .finally(() => {
      setLoading(false);
    });
  }

  function addTicketToCart(index, setLoading) {
    addTicketToCartCall(index, setLoading);
  }

  function addTicketToCartCall(index, setLoading, refreshAfter = false) {
    setLoading(true);
    axios.post('/api/int/v1/shopping_carts/add_item', { cart_item: { campaign_id: campaign.id, index: index } })
      .then(response => {
        setCartCount(response.data.items.length);
        toast.success('Título adicionado ao carrinho');
        let itemsCopy = items.slice();
        itemsCopy.splice(index, 1);
        setItems(itemsCopy);
        if (refreshAfter) {
          loadItems();
        }
      })
      .catch(error => {
        if (error.response.status == 422 && error.response.data.error == 'cart_full_error') {
          toast.error('Carrinho cheio, finalize sua compra');
        } else {
          toast.error('Erro ao processar operação, tente em instantes');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Carousel autoplay>
        {
          campaign && 
            <div key={campaign.id}>
              <img src={isMobile ? campaign.mobile_banner_url : campaign.web_banner_url} alt={campaign.title} className="campaign-desktop-banner" />
            </div>
        }
      </Carousel>
      <div className="smaller_container">
        {
          campaign &&
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <h1 style={{marginBottom: 0}}>
              Escolha seus títulos
              <br/>
              <small>{campaign.title}</small>
            </h1>
            <Flex justify="space-between" align="center" gap="medium" wrap="wrap" id="items_to_choose">
              {
                items.map((item, index) => (
                  <ItemToChoose item={item} index={index} addTicketToCart={addTicketToCart} key={item.number} />
                ))
              }
              <div className="item_to_choose" key="extra_1" />
              <div className="item_to_choose" key="extra_2" />
              <div className="item_to_choose" key="extra_3" />
            </Flex>
            <p className="center">
              <Link to={`/${project.name}/site/carrinho`}>
                <Button size="medium" loading={loading} type="primary" shape="round">
                  Ir para o meu carrinho
                </Button>
              </Link>
              <br />
              <br />
              <Button size="medium" loading={loading} type="default" shape="round" onClick={loadItems}>
                Ver mais títulos
              </Button>
            </p>
          </Space>
        }
      </div>
    </div>
  )
}
