import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify'
import { HomeOutlined, UserOutlined, MobileOutlined, ContactsOutlined, EnvironmentOutlined } from '@ant-design/icons';
import MaskedInput from '../../components/MaskedInput';
import { Input, Button, Form, Layout, Select } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import { UserContext } from '../App.js';
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';

const { Content } = Layout;

export default function SignUp({isMobile, project, app_settings}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const disabled = false;
  const { user, setUser } = useContext(UserContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [cities, setCities] = useState([]);
  const [cityApiError, setCityApiError] = useState(false);
  const { phone_validation, email_input } = app_settings;

  function sign_up(formData) {
    document.activeElement.blur();
    setLoading(true);
    
    axios.post('/customers', { customer: { ...formData, password: '********', password_confirmation: '********' } })
      .then(function(response){
        if (app_settings.root_to_login) {
          if (app_settings.phone_validation)
            window.location.href = `/${project.name}/site/acesso/confirmar-telefone`;
          else
            window.location.href = `/${project.name}/site/minha-conta`;
        } else {
          window.location.href = `/${project.name}/site`
        }
      })
      .catch(function(error){
        toast.error('Erro ao processar solicitação.');
        if (error.response.status == 422){
          setErrors(error.response.data.errors);
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!user || !user.cpf) {
      history.push(`/${project.name}/site/cpf`);
    } else {
      form.setFieldsValue({ cpf: user.cpf });
      setTimeout(checkCpfResultTask, 500);
    }
  }, []);

  function checkCpfResultTask() {
    axios.get('/customers/cpf/check_results', { params: { cpf: user.cpf } })
      .then(function(response){
        if (response.data.status == 'waiting') {
          setTimeout(checkCpfResultTask, 2000);
        } else if (response.data.status == 'less_than18') {
          toast.error('Você precisa ter mais de 18 anos para se cadastrar.');
          setErrors({ cpf: ['Você precisa ter mais de 18 anos para se cadastrar.'] });
          setTimeout(() => history.push('/site/cpf'), 5000);
        } else if (response.data.status == 'pep') {
          toast.error('Você é uma pessoa politicamente exposta e não pode participar.');
          setErrors({ cpf: ['Você é uma pessoa politicamente exposta e não pode participar.'] });
          setTimeout(() => history.push('/site/cpf'), 5000);
        } else if (response.data.status == 'invalid') {
          setErrors({ cpf: ['CPF inválido.'] });
          toast.error('CPF inválido.');
          setTimeout(() => history.push('/site/cpf'), 5000);
        } else {
          form.setFieldsValue({ name: response.data.name });
          setLoading(false);
        }
      })
      .catch(function(error) {
        setTimeout(checkCpfResultTask, 5000);
      });
  }

  function loadCities(value) {
    setCities([]);
    axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`,
      {
        timeout: 2000
      }
    )
      .then(function(response){
        setCities(response.data);
      })
      .catch(function(error){
        setCityApiError(true);
        console.log(error);
      });
  }

  function handleStateChange(value) {
    form.setFieldsValue({ city: undefined });
    loadCities(value);
  }

  return (
    <>
      <PurchaseOrderSteps step={1} isMobile={isMobile} />
      <Content className="smallest_container">
        <h1>
          Boas vindas à Nacional Cap
        </h1>
        <p className="center">
          Precisamos identificá-lo para prosseguirmos.
        </p>
        <Form form={form} layout="vertical" name="sign_up" className="login-form" initialValues={{ remember: true }} onFinish={sign_up}>
          <Form.Item name="cpf" rules={[{ required: true }]} label="CPF" required hasFeedback={!!errors['cpf']} validateStatus={errors['cpf'] && 'error'} help={errors['cpf']?.join(', ')}>
            <MaskedInput name="phone" form={form} disabled={true} prefix={<UserOutlined className="site-form-item-icon" />}  mask="999.999.999-99" placeholder="000.000.000-00" />
          </Form.Item>
          {
            email_input && <Form.Item name="email" label="E-mail" hasFeedback={!!errors['email']} validateStatus={errors['email'] && 'error'} help={errors['email']?.join(', ')}>
                            <Input autoFocus prefix={<ContactsOutlined className="site-form-item-icon" />} />
                          </Form.Item>
          }
          <Form.Item name="phone" rules={[{ required: true }]} required label="Telefone" hasFeedback={!!errors['phone']} validateStatus={errors['phone'] && 'error'} help={errors['phone']?.join(', ')}>
            <MaskedInput name="phone" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<MobileOutlined className="site-form-item-icon" />} mask="(99) 99999 9999" placeholder="(99) 99999 9999" />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true }]} required label="Nome" hasFeedback={!!errors['name']} validateStatus={errors['name'] && 'error'} help={errors['name']?.join(', ')}>
            <Input prefix={<ContactsOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="state" rules={[{ required: true }]} required label="Estado" hasFeedback={!!errors['state']} validateStatus={errors['state'] && 'error'} help={errors['state']?.join(', ')}>
            <Select
              placeholder="UF"
              onChange={handleStateChange}
              showSearch
              optionFilterProp="label"
              allowClear
              options={[
                { value: 'AC', label: 'AC' },
                { value: 'AL', label: 'AL' },
                { value: 'AP', label: 'AP' },
                { value: 'AM', label: 'AM' },
                { value: 'BA', label: 'BA' },
                { value: 'CE', label: 'CE' },
                { value: 'ES', label: 'ES' },
                { value: 'GO', label: 'GO' },
                { value: 'MA', label: 'MA' },
                { value: 'MT', label: 'MT' },
                { value: 'MS', label: 'MS' },
                { value: 'MG', label: 'MG' },
                { value: 'PA', label: 'PA' },
                { value: 'PB', label: 'PB' },
                { value: 'PR', label: 'PR' },
                { value: 'PE', label: 'PE' },
                { value: 'PI', label: 'PI' },
                { value: 'RJ', label: 'RJ' },
                { value: 'RN', label: 'RN' },
                { value: 'RS', label: 'RS' },
                { value: 'RO', label: 'RO' },
                { value: 'RR', label: 'RR' },
                { value: 'SC', label: 'SC' },
                { value: 'SP', label: 'SP' },
                { value: 'SE', label: 'SE' },
                { value: 'TO', label: 'TO' },
                { value: 'DF', label: 'DF' }
              ]}
            >
            </Select>
          </Form.Item>
          <Form.Item name="city" rules={[{ required: true }]} required label="Cidade" hasFeedback={!!errors['city']} validateStatus={errors['city'] && 'error'} help={errors['city']?.join(', ')}>
            {
              cityApiError ?
                <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
                :
                <Select
                  disabled={cities.length == 0}
                  placeholder="Cidade"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().startsWith(input.toLowerCase())
                  }
                  loading={cities.length == 0}
                  optionFilterProp="label"
                  options={cities.map(city => ({ value: city.nome, label: city.nome }))}
                >
                </Select>   
            }
          </Form.Item>

          <p style={{ fontSize: '14px', paddingTop: '10px' }}>
            Ao clicar em "Criar conta", você concorda com os nossos <a href='/site/termos' target='_blank'>Termos e condições</a> e nossa <a href='/site/politicas' target='_blank'>Política de privacidade</a>.
          </p>
          <Form.Item>
            <Button shape="round" block type="primary" htmlType="submit" disabled={disabled} loading={loading} className="login-form-button">
              Criar conta
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
}