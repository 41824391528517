import React, { useState, useEffect } from 'react';

export default function YoutubeVideo({videoId, width, height, paddingBottom}) {
  // width = width || 560;
  height = height || 595;
  let styles = {
    height: height,
  }
  if (paddingBottom) {
    styles.paddingBottom = paddingBottom;
  }


  return (
    <div className="video-responsive" style={styles}>
      <iframe src={`https://www.youtube.com/embed/${videoId}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  )
}