import React, { useState } from 'react';
import { lpad } from '../single-page/utils/index.js';

export default function ItemToCheck({item, campaign, checkedNumbers, checkOrUncheckNumber}) {
  const [loading, setLoading] = useState(false);



  return (
    <div className="item_to_check rounded_square">
      <p style={{paddingLeft: 10}}><b>Título: </b>{lpad(item.number, 8)}</p>
      {
        campaign?.bingo &&
          <p>
            {
              item.numbers.map(number => (
                <span key={number} onClick={() => checkOrUncheckNumber(number)} className={`bingo_ball ${checkedNumbers.includes(number) ? 'checked' : ''}`}>{lpad(number, 2)} </span>
              ))
            }
          </p>
      }
    </div>
  )
}