import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Carousel, Skeleton, Spin } from 'antd';
import CampaignFastBuy from '../../components/CampaignFastBuy';


export default function BuyNow({currentUser, project, isMobile}) {
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.get(`/api/int/v1/campaigns/${project.name}/default`)
      .then(response => {
        setCampaign(response.data);
      })
      .catch(error => {
        toast.error('Erro ao carregar campanha');
      })
      .then(() => {
        setLoading(false);
      });
  }, []);


  return (
    <div>
      {
        loading ?
          <>
            <div className="center">
              <Spin size="large" style={{marginTop: 120}} />
              {/* <Skeleton.Node active={true} style={{width: '100vw', height: '300px'}} />
              <Skeleton active={true} paragraph={{rows: 4}} rows={2} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <br/>
              <Skeleton.Button active={true} shape="round" size="large" style={{width: 180}} /> */}
            </div>      
          </>
          :
          <>
            <Carousel autoplay>
              {
                campaign && 
                  <div key={campaign.id}>
                    <img src={isMobile ? campaign.mobile_banner_url : campaign.web_banner_url} alt={campaign.title} className="campaign-desktop-banner" />
                  </div>
              }
            </Carousel>
            <div className="container">
              <CampaignFastBuy campaign={campaign} currentUser={currentUser} project={project} />
            </div>
          </>
        }
    </div>
  );
}