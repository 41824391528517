import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { numberAsCurrency } from '../../utils';

export default function CartItem({item, removeItem, index}) {
  return (
    <div className="cart_item">
      <p>
        {
          <span style={{float: 'right'}}><Button style={{height: 'unset', width: 'unset', padding: 0, marginLeft: 20}} onClick={() => removeItem(index)} type="link" icon={<DeleteFilled style={{color: 'gray'}} />}></Button></span>
        }
        <span style={{float: 'right', fontWeight: 'bold'}}>{numberAsCurrency(item.price)}</span>
        <span><b>Campanha:</b> {item.campaign.title}</span><br/>
        <span><b>Título:</b> {item.number}</span>
      </p>
      {
        item.campaign?.bingo &&
          <p>
            {
              item.numbers.slice(0, 10).map((number, index) => {
                return (
                  <span key={index} className="bingo_ball">
                    {number}
                  </span>
                );
              })
            }
            <br/>
            {
              item.numbers.slice(10, 20).map((number, index) => {
                return (
                  <span key={index} className="bingo_ball">
                    {number}
                  </span>
                );
              })
            }
          </p>
      }
    </div>
  );
}