import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Badge } from 'antd'
import LogoIcon from '../../images/logo.svg'
import ArrowIcon from '../../images/arrow.svg'
import { UserOutlined, StarOutlined, ShoppingCartOutlined, QuestionCircleOutlined, WhatsAppOutlined } from '@ant-design/icons'
import axios from 'axios'
import { CartContext } from '../App.js';

const { Header } = Layout


export default function DesktopMenu({ currentUser, currentAffiliate, isMobile, project }) {
  const { cartCount, setCartCount } = React.useContext(CartContext);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const smallScreen = screenWidth < 1500;
  const smallerScreen = screenWidth < 1250;

  function handleLogout() {
    axios.delete('/customers/sign_out.json')
      .then(function(res){
        window.location.href = `/${project.name}/site`
      })
      .catch(function(error){
        console.log(error);
      });
  }

  const rightMenuStyle = isMobile ? { flex: '0 0 85px', minWidth: 0 } : { flex: '0 0 220px', minWidth: 0 }
  const leftMenuStyle = isMobile ? { flex: '1', minWidth: 0, flexDirection: 'row-reverse' } : { flex: '1', minWidth: 0 }
  const userMenuProps = isMobile ? 
    {
      icon: <UserOutlined style={{fontSize: '23px'}} />,
      className: "mobile_profile_menu_icon"
    }
    :
    {
      title: currentUser?.name.split(' ')[0],
    };

  return (
    <Header style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ height: '100%' }}>
        <Link to={`/${project.name}/`}>
          <img src={project.logo || LogoIcon} id="header_logo" />
        </Link>
      </div>
      <Menu mode="horizontal" style={leftMenuStyle}>
        {
          !isMobile &&
            <Menu.Item key="0" style={{ textDecoration: 'none' }}>
              <Link to={`/${project.name}/site/compre-agora`}>
                <span className="roundedButton" style={{ backgroundColor: '#F6EB1C', color: '#303483' }}>
                  { smallScreen ? 'Comprar' : 'Compre Agora' }
                  <img src={ArrowIcon} style={{ height: 12 }} />
                </span>
              </Link>
            </Menu.Item>    
        }
        {
          !isMobile &&
            <Menu.Item key="1">
              <Link to={`/${project.name}/`}>
                Início
              </Link>
            </Menu.Item>
        }
        {
          !isMobile && !smallerScreen &&
            <Menu.Item key="2">
              <Link to={`/${project.name}/site/quem-somos`}>
                { smallScreen ? 'Sobre' : 'Quem somos' }
              </Link>
            </Menu.Item>
        }
        {
          !isMobile &&
            <Menu.SubMenu type="group" key="3" title="Revendedor">
              {
                !currentAffiliate && 
                  <Menu.Item key="3.1">
                    <Link to={`/site/revendedor/cpf`}>
                      Seja um Revendedor
                    </Link>
                  </Menu.Item>
              }
              <Menu.Item key="3.2">
                <a href="/revendedor">
                  Painel do Revendedor
                </a>
              </Menu.Item>
            </Menu.SubMenu>
        }
        {
          !isMobile &&
            <Menu.Item key="4">
              <Link to={`/${project.name}/site/acompanhar-sorteio`}>
                { smallScreen ? 'Live' : 'Acompanhar sorteio' }
                {/* Acompanhar sorteio */}
              </Link>
            </Menu.Item>
        }
        {
          !isMobile &&
            <Menu.Item key="5">
              <Link to={`/${project.name}/site/resultados`}>
                Resultados
              </Link>
            </Menu.Item>
        }
        {/* <Menu.Item key="5">
          <a href="https://wa.me/5511994283300" target="_blank" rel="noopener noreferrer">
            <WhatsAppOutlined style={{fontSize: '23px'}} />
          </a>
        </Menu.Item> */}
        {
          !isMobile &&
          <Menu.Item key="7">
            <Link to={`/${project.name}/site/faq`}>
              <QuestionCircleOutlined style={{fontSize: '23px'}} />
            </Link>
          </Menu.Item>
        }
      </Menu>
      <Menu mode="horizontal" style={rightMenuStyle}>
        {
          currentUser ? (
            <Menu.SubMenu type="group" key="7" {...userMenuProps}>
              <Menu.Item key="7.1">
                <Link to={`/${project.name}/site/minha-conta`}>
                  Minha Conta
                </Link>
              </Menu.Item>
              <Menu.Item key="7.2">
                <Link to="#" onClick={handleLogout}>
                  Sair
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          ) : (
            <Menu.Item key="8" className="mobile_profile_menu_icon">
              <Link to={`/${project.name}/site/acesso/cpf?history=true`} style={{ color: 'white' }}>
                {
                  isMobile ? <UserOutlined style={{fontSize: '23px'}} /> : smallScreen ? 'Meus títulos' : 'Consultar títulos'
                }
              </Link>
            </Menu.Item>
          )
        }
        <Menu.Item key="9">
          <Link to={`/${project.name}/site/carrinho`}>
            <Badge count={cartCount} size="small">
              <ShoppingCartOutlined style={{fontSize: '23px'}} />
            </Badge>
          </Link>
        </Menu.Item>
      </Menu>
    </Header>
  );
}