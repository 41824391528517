import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { List, Button } from 'antd';
import { Link } from 'react-router-dom';
import { numberAsCurrency, lpad } from '../../utils/index.js';
import SadIcon from '../../../images/sad.svg';
import moment from 'moment';

export default function MyOrders({project}) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  function fetchOrders() {
    setLoading(true);
    axios.get(`/api/int/v1/${project.name}/purchase_orders`, { params: { page: page } })
      .then(function(res){
        setList([...list, ...res.data.purchase_orders]);
        setPage(page + 1);
        setHasMore(res.data.next_page);
      })
      .catch(function(error){
        console.log(error);
        toast.error('Erro ao carregar pedidos');
      })
      .finally(function(){
        setLoading(false);
        setInitLoading(false);
      });
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  function colorForStatus(status) {
    switch (status) {
      case 'waiting_payment':
        return '#FFD700';
      case 'paid':
        return 'green';
      case 'canceled':
        return '#FF0000';
      case 'expired':
        return '#FF0000';
      case 'error':
        return '#FF0000';
      default:
        return 'green';
    }
  }

  function emptyList() {
    return (
      <div className="center" style={{paddingTop: 40}}>
        <h1>
          Você não tem nenhum pedido
          <img src={SadIcon} style={{width: 30, marginLeft: 10, verticalAlign: 'middle'}} />
        </h1>
        <p>
          Participe do próximo sorteio, compre seus títulos clicando no botão agora:
        </p>
        <p>
          <Link to={`/${project.name}/site/compre-agora`}>
            <Button size="medium" shape="round" type="primary">
              Compre Agora
            </Button>
          </Link>
        </p>
      </div>
    );
  }

  return (
    <div style={{paddingBottom: 40}}>
      <List
        className="meus-pedidos"
        loading={initLoading}
        locale={{emptyText: emptyList()}}
        itemLayout="horizontal"
        loadMore={!initLoading && !loading && hasMore && <div className="center"><Button type="primary" shape="round" size="small" onClick={fetchOrders}>Carregar mais</Button></div>}
        dataSource={list}
        renderItem={(item) => (
          <List.Item>
            <Link to={`/${project.name}/site/pedido/${item.id}`} style={{display: 'block', width: '100%'}}>
              <div className="rounded_square order">
                <h4>Pedido {lpad(item.id, 6)}</h4>
                <p>
                  <span className="float_right">{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</span>
                  Quantidade de títulos: <b>{item.tickets_amount}</b>
                </p>
                <p>
                  <span className="float_right" style={{color: colorForStatus(item.status)}}>{item.user_status}</span>
                  Valor total: <b>{numberAsCurrency(item.total_value)}</b>
                </p>
              </div>
            </Link>
          </List.Item>
        )}
      />
    </div>
  );
}