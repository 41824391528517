import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ResultHeader from '../../../components/ResultHeader';
import { Button } from 'antd';

export default function ResultHomeIndex({results, project}) {

  if (!results || results.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="container">
        <h1>Últimos resultados</h1>
        <div>
          {
            results.map(result => (
              <ResultHeader key={result.id} result={result} />
            ))
          }
        </div>
        <p className="center">
          <Link to={`/${project.name}/site/resultados`}>
            <Button shape="round" type="default">Ver mais</Button>
          </Link>  
        </p>
      </div>
    </div>
  );
}