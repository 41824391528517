import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SmallWinner from '../../components/SmallWinner';
import { Select, Flex, Spin } from 'antd';
import YoutubeVideo from '../../components/YoutubeVideo';

export default function Results({project}) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const initializedSelect = useRef(false);
  const campaignIdParameter = new URLSearchParams(window.location.search).get('campaign_id');

  useEffect(() => {
    loadCampaigns();
  }, []);

  useEffect(() => {
    if (!selectedCampaign) return;

    if (initializedSelect.current) {
      loadResults();
    }
    initializedSelect.current = true;
  }, [selectedCampaign]);

  function handleChangeCampaign(value) {
    setSelectedCampaign(value);
    setResults([]);
  }

  function loadCampaigns() {
    axios.get(`/api/int/v1/campaigns/${project.name}/select_and_results`)
      .then(function(response){
        setCampaigns(response.data.campaigns);
        setResults(response.data.prizes);
        if (response.data.campaigns.length > 0) {
          let sel = null;
          if (campaignIdParameter) {
            sel = response.data.campaigns.filter((c) => c.value == campaignIdParameter)?.value
          }
          if (sel) {
            setSelectedCampaign(sel);
          } else {
            setSelectedCampaign(response.data.campaigns[0].value);
          }
        }
      })
      .catch(function(error){
        console.log(error);
        toast.error('Erro ao carregar campanhas');
      })
      .then(function() {
        setLoading(false);
      });
  }

  function loadResults() {
    setLoading(true);
    axios.get('/api/int/v1/campaigns/results', { params: { campaign_id: selectedCampaign } })
      .then(function(response){
        setResults(response.data);
      })
      .catch(function(error){
        console.log(error);
        toast.error('Erro ao carregar resultados desta campanha');
      })
      .then(function() {
        setLoading(false);
      });
  }

  return (
    <div className="container" id="results">
      <h1>Resultados</h1>
      <br/>
      {
        !results && !loading ?
          <div className="center">
            <h2>Nenhum resultado encontrado</h2>
          </div>
          :
          <>
            <small>
              Mostrando resultados da campanha &nbsp;
              <Select
                style={{minWidth: 150}}
                size="small"
                value={selectedCampaign}
                onChange={handleChangeCampaign}
                options={campaigns}
              />
              &nbsp; Clique para trocar
            </small>
            <br/><br/>
            {
              loading ?
                <>
                  <div className="center">
                    {/* <Skeleton.Image active={true} style={{width: '100%', height: '300px'}} />
                    <Row style={{marginTop: 40}}>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <Skeleton.Node active={true} style={{width: '300px'}} />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Skeleton.Node active={true} style={{width: '300px'}} />
                      </Col>
                      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <Skeleton.Node active={true} style={{width: '300px'}} />
                      </Col>
                    </Row> */}
                    <Spin size="large" style={{marginTop: 120}} />
                  </div>      
                </>
              :
                <>
                  {
                    results && results.length > 0 && results[0].campaign.live_id &&
                      <div className="purple_bg">
                        <div className="smaller_container">
                          <YoutubeVideo videoId={results[0].campaign.live_id} height={250} paddingBottom='45%' />
                        </div>
                      </div>
                  }
                  <br/><br/>
                  
                  <Flex justify="space-evenly" wrap="wrap" align="top" gap="large" style={{paddingBottom: 20, paddingTop: 0}}>
                    {results && results.map((result) => (
                      <div className="result_item">
                        <div className={`result ${result.prize_type}`}>
                          {
                            result.banner_url ?
                              <img src={result.banner_url} alt={result.title} width="100%" />
                              :
                              <img src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='400' height='20'%3E%3Crect width='400' height='20' fill='white'/%3E%3C/svg%3E" alt={result.title} width="100%" />
                          }
                          <h3 className="purple">{result.title}</h3>
                          {
                            result.prize_type === 'bingo' &&
                              <div>
                                {
                                  result.result_numbers.map((number) => (
                                    <span key={number} className="bingo_ball">{number}</span>
                                  ))
                                }
                              </div>
                          }
                          <div>
                            {
                              result.winners.length > 0 ?
                                <>
                                  <h4>Ganhadores</h4>
                                  <div class="winners">
                                    {
                                      result.winners.map((winner) => (
                                        <SmallWinner winner={winner} />
                                      ))
                                    }
                                  </div>
                                </>
                                :
                                <>
                                  <h4>Nenhum ganhador ainda...</h4>
                                  <div class="winners">
                                  </div>
                                </>
                            }
                            
                          </div>
                        </div>
                      </div>
                    ))}
                  </Flex>
                </>
            }
          </>
      }
    </div>
  );
}