import React, { useState, useEffect } from 'react';
import ClockIcon from '../../../images/clock.svg';
import { Row, Col } from 'antd';
import moment from 'moment';
import { CartContext } from '../../App';

const boxStyle = {
  border: '1px solid #ccc',
  padding: '12px',
  marginBottom: '10px',
  borderRadius: '15px',
  backgroundColor: '#ECECEC'
};

export default function CartTimer({ cart }) {
  const { cartCount, setCartCount } = React.useContext(CartContext);

  if (!cart.items || cart.items.length === 0) return (<div></div>);

  function minutesLeft(cart) {
    const now = moment();
    const expires_at = moment(cart.expires_at);
    if (expires_at.isBefore(now)) return '00:00';
    return moment.utc(moment.duration(expires_at.diff(now)).asMilliseconds()).format('mm:ss');
  }
  const [timeLeft, setTimeLeft] = useState(minutesLeft(cart));

  useEffect(() => {
    if (cart) {
      const interval = setInterval((cart) => {
        var tl = minutesLeft(cart);

        if (tl === '00:00') {
          setTimeout(() => {
            window.location.reload();
          }, 1500);
          setCartCount(0);
        }
        setTimeLeft(tl);
      }, 1000, cart);
      return () => clearInterval(interval);
    }
  }, [cart]);

  return (
    <div style={boxStyle}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={10} xl={10} className="center">
          <p style={{margin: 0}}>
            <img src={ClockIcon} style={{width: '41px', height: '41px', verticalAlign: 'middle'}} />
            <span style={{verticalAlign: 'center', fontSize: 32, color: '#330A8B', fontWeight: 'bold', minWidth: '90px', display: 'inline-block', verticalAlign: 'middle'}}>{timeLeft}</span>
          </p>
        </Col>
        <Col xs={24} sm={24} md={24} lg={14} xl={14}>
          <p style={{verticalAlign: 'center', margin: 0, textAlign: 'center'}}>Após este tempo, os títulos serão liberados para venda novamente</p>
        </Col>
      </Row>
    </div>
  );
}