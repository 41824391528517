import React, { useState, useEffect } from 'react';
import CartTimer from './CartTimer';
import { Space, Button } from 'antd';
import { numberAsCurrency, lpad } from '../../utils';
import PixIcon from '../../../images/pix.svg';


const boxStyle = {
  border: '1px solid #ccc',
  padding: '25px',
  marginBottom: '10px',
  borderRadius: '15px',
};

const purpleNumber = {
  color: '#253875',
  fontWeight: 'bold',
  fontSize: 18,
  float: 'right',
};

const rootStyle = {
  padding: '20px',
}

export default function CartDetails({cart, buyTickets}) {
  if (!cart.items) return (<div></div>);

  return (
    <div style={rootStyle}>
      <CartTimer cart={cart} />
      <div style={boxStyle}>
        <Space direction="vertical" size="small" style={{width: '100%'}}>
          <p>
            <span>Quantidade de títulos:</span>
            <span style={purpleNumber}>{lpad(cart.items.length, 2)}</span>
          </p>
          <p>
            <span>Valor total a pagar:</span>
            <span style={purpleNumber}>{numberAsCurrency(cart.items.reduce((acc, item) => acc + parseFloat(item.price), 0))}</span>
          </p>
          <p><span>Forma de pagamento:</span></p>
          <div style={{ ...boxStyle, padding: 15, fontWeight: 'bold', fontSize: 18}}>
            <img src={PixIcon} style={{width: '35px', height: '35px', verticalAlign: 'middle', marginRight: 15}} />
            Pix
          </div>
          <p style={{textAlign: 'center', margin: 0}}>
            <Button onClick={buyTickets} disabled={cart.items.length == 0} shape="round" type="primary">Comprar Títulos</Button>
          </p>
        </Space>
      </div>
    </div>
  );
}