import React from 'react';
import { Layout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import InstaIcon from '../images/ig.png';
import FaceIcon from '../images/fb.png';
import YoutubeIcon from '../images/yt.png';
import ZapIcon from '../images/zap.png';
import RdmLogo from '../images/rdm.png';
import Logo from '../images/logo-footer.svg';
const { Footer } = Layout;

export default function AppFooter({project, site_texts}) {
  return (
    <Footer style={{ textAlign: 'left', color: 'white', overflowWrap: 'break-word' }}>
      <div className="container">
        <Row>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} style={{padding: 5}}>
            <p>
              <img style={{ padding: '0 20px', maxWidth: '100%' }} src={Logo} />
            </p>
            <p>
              Prêmios incríveis que podem mudar o seu estilo de vida!
            </p>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} style={{padding: 5}}>
            <h2>Contato</h2>
            <h3>
              <a href="mailto:contato@nacionalcap.com.br" target="_blank" rel="noopener noreferrer">
                contato@nacionalcap.com.br
              </a>
            </h3>
            <p>
              Diariamente, das 07h às 19h
            </p>
            <p>
              Verifique nossos&nbsp;
              <Link to={`/${project.name}/site/termos`} style={{textDecoration: 'underline'}}>
                Termos de Uso
              </Link>
              &nbsp;e&nbsp;
              <Link to={`/${project.name}/site/politicas`} style={{textDecoration: 'underline'}}>
                Política de privacidade
              </Link>
            </p>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} style={{padding: 5}}>
            <h2>Sobre nós</h2>
            <p>
              <a href="https://www.instagram.com/nacionalcapoficial/" target="_blank">
                <img src={InstaIcon} style={{ height: 28 }} />
              </a>
              <a href="https://www.facebook.com/people/Nacional-Cap/61552260466503/?mibextid=ZbWKwL%2F" target="_blank">
                <img src={FaceIcon} style={{ height: 28 }} />
              </a>
              <a href="https://www.youtube.com/@Nacional_CAP" target="_blank">
                <img src={YoutubeIcon} style={{ height: 28 }} />
              </a>
            </p>
            <p>
              <Link to={`/${project.name}/site/quem-somos`}>
                Quem somos
              </Link>
            </p>
          </Col>
          <Col span={24} style={{padding: 5}}>
            <div dangerouslySetInnerHTML={{ __html: site_texts.footer.body }} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: 5}}>
            <p>Imagens meramente ilustrativas</p>
          </Col>
          {/* <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{padding: 5}} className="author">
            Sistema desenvolvido pela &nbsp;
            <a target="_blank" href="https://www.rdmapps.com.br/">
              <img height="20" src={RdmLogo} style={ { transform: "translateY(5px)"}} />
            </a>
          </Col> */}
        </Row>
      </div>
    </Footer>
  );
}