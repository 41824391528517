import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import YoutubeVideo from '../../components/YoutubeVideo';
import { toast } from 'react-toastify';
import { Flex, Select, Button, Spin } from 'antd';
import SadIcon from '../../images/sad.svg';
import { Link } from 'react-router-dom';
import ItemToCheck from '../../components/ItemToCheck';

export default function Live({currentUser, project}) {
  const [videoId, setVideoId] = useState(null);
  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60];
  const [checkedNumbers, setCheckedNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userTickets, setUserTickets] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const initializedSelect = useRef(false);

  useEffect(() => {
    fetchUserTickets();
  }, []);

  function handleChangeCampaign(value) {
    setUserTickets([]);
    setCheckedNumbers([]);
    setSelectedCampaign(value);
  }

  function clearData() {
    setCheckedNumbers([]);
  }

  useEffect(() => {
    if (!selectedCampaign) return;

    if (initializedSelect.current) {
      fetchUserTickets();
    }
    initializedSelect.current = true;
  }, [selectedCampaign]);

  function fetchUserTickets() {
    setLoading(true);
    axios.get(`/api/int/v1/customers/${project.name}/live`, { params: { campaign_id: selectedCampaign } })
      .then((response) => {
        if (response.data.tickets.length >= 51) {
          toast.error('Iremos exibir apenas as primeiras 50 cartelas. Mas não se preocupe, todas estão participando do sorteio!');
          setUserTickets(response.data.tickets.slice(0, 50));
        } else {
          setUserTickets(response.data.tickets);
        }
        setCampaigns(response.data.campaigns);
        setSelectedCampaign(response.data.selected_id);
        setCurrentCampaign(response.data.campaigns.find(campaign => campaign.id === response.data.selected_id));
        setVideoId(response.data.video_id);
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao carregar suas cartelas');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function checkOrUncheckNumber(number) {
    if (checkedNumbers.includes(number)) {
      setCheckedNumbers(checkedNumbers.filter(n => n !== number));
      userTickets.map((ticket) => {
        if (ticket.numbers.includes(number)) {
          ticket.checks -= 1;
        }
      });
    } else {
      setCheckedNumbers([...checkedNumbers, number]);
      userTickets.map((ticket) => {
        if (ticket.numbers.includes(number)) {
          if (!ticket.checks) {
            ticket.checks = 1;
          } else {
            ticket.checks += 1;
          }
        }
      });
    }
    reorderUserTickets();
  }

  function reorderUserTickets() {
    const sortedTickets = userTickets.sort((a, b) => {
      return (b.checks || 0) - (a.checks || 0);
    });
    setUserTickets(sortedTickets);
  }

  return (
    <div>
      <h1>Acompanhe o sorteio ao vivo!</h1>
      <div className="purple_bg">
        <div className="smaller_container">
          <YoutubeVideo videoId={videoId} height={250} paddingBottom='45%' />
        </div>
      </div>
      {
        currentCampaign?.bingo &&
          <div style={{maxWidth: '980px', margin: '0 auto', overflowX: 'scroll', padding: '10px', '-webkit-overflow-scrolling': 'touch'}} >
            <div id="numbers_to_check">
              { 
                numbers.map((number) => (
                  <span key={number} className={`bingo_ball_check ${checkedNumbers.includes(number) ? 'checked' : ''}`} onClick={() => checkOrUncheckNumber(number)}>
                    {number}
                  </span>
                ))
              }
            </div>
          </div>
      }
      <div className="container">
        {
          currentUser ?
            <>
              <h1 style={{marginTop: '15px'}}>
                Seus títulos<br/>
                <small>
                  Mostrando títulos da campanha &nbsp;
                  <Select
                    size="small"
                    style={{minWidth: 150}}
                    value={selectedCampaign}
                    onChange={handleChangeCampaign}
                    options={campaigns.map(campaign => ({ value: campaign.id, label: campaign.title }))}
                  />
                  {
                    currentCampaign?.bingo && checkedNumbers.length > 0 &&
                      <Button type="link" onClick={clearData}>Limpar cartelas</Button>
                  }
                </small>
              </h1>
              {
                loading ?
                  <div className="center">
                    <Spin size="large" style={{margin: '40px 0px'}} />
                  </div>
                :
                  userTickets.length === 0 ?
                  <p>
                    <div className="center" style={{paddingTop: 20}}>
                      <p>
                        Você não possui títulos desta campanha
                        <img src={SadIcon} style={{width: 30, marginLeft: 10, verticalAlign: 'middle'}} />
                      </p>
                      <p>
                        Participe do próximo sorteio, compre seus títulos clicando no botão agora:
                      </p>
                      <p>
                        <Link to={`/${project.name}/site/compre-agora`}>
                          <Button size="medium" shape="round" type="primary">
                            Compre Agora
                          </Button>
                        </Link>
                      </p>
                    </div>
                  </p>
                  :
                  <Flex justify="space-evenly" align="center" gap="medium" wrap="wrap" id="items_to_check">
                    {
                      userTickets.map(item => (
                        <ItemToCheck campaign={currentCampaign} item={item} key={item.number} checkedNumbers={checkedNumbers} checkOrUncheckNumber={checkOrUncheckNumber} />
                      ))
                    }
                    <div className="item_to_check" />
                    <div className="item_to_check" />
                    <div className="item_to_check" />
                  </Flex> 
              }
            </>
            :
            <div className="center">
              <h3>Para ver seus títulos, faça login clicando no botão abaixo</h3>
              <p>
                <Link to={`/${project.name}/site/login`}>
                  <Button type="primary">Login</Button>
                </Link>
              </p>
            </div>
        }
      </div>
    </div>
  );
}