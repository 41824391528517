import React, { useState, useEffect } from 'react';
import { Space } from 'antd';
import { numberAsCurrency, lpad } from '../../utils';
import PixIcon from '../../../images/pix.svg';


const boxStyle = {
  border: '1px solid #ccc',
  padding: '25px',
  marginBottom: '10px',
  borderRadius: '15px',
};

const purpleNumber = {
  color: '#253875',
  fontWeight: 'bold',
  fontSize: 18,
  float: 'right',
};

const rootStyle = {
  padding: '20px',
}

export default function OrderSummary({order}) {
  if (!order) return (<div></div>);

  return (
    <div style={rootStyle}>
      <div style={boxStyle}>
        <Space direction="vertical" size="small" style={{width: '100%'}}>
          <p>
            <span>Quantidade de títulos:</span>
            <span style={purpleNumber}>{order.tickets_amount}</span>
          </p>
          <p>
            <span>Valor total do pedido:</span>
            <span style={purpleNumber}>{numberAsCurrency(order.total_value)}</span>
          </p>
          {
            order.status == 'waiting_payment' &&
            (
              <>
                <p><span>Forma de pagamento:</span></p>
                <div style={{ ...boxStyle, padding: 15, fontWeight: 'bold', fontSize: 18}}>
                  <img src={PixIcon} style={{width: '35px', height: '35px', verticalAlign: 'middle', marginRight: 15}} />
                  Pix
                </div>
              </>
            )
          }
        </Space>
      </div>
    </div>
  );
}