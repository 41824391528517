import React, { useState } from 'react';
import { Collapse, Modal } from 'antd';
import BlueUp from '../images/blue-up.svg';
import BlueDown from '../images/blue-down.svg';
import Ticket from './Ticket';
import moment from 'moment';
import { numberAsCurrency, lpad } from '../single-page/utils';

const { Panel } = Collapse;

export default function InstantPrizesSummary({campaign}) {  
  if (!campaign || campaign?.instant_prizes?.length == 0) return null;

  const [modalOpen, setModalOpen] = useState(false);
  const [winner, setWinner] = useState(null);
  let width = campaign.digits * 15;
  if (campaign.digits == 3) {
    width = 53;
  }

  const anyPrizeToShow = campaign.instant_prizes.some(prize => prize.show_winners_instantly === true);

  if (anyPrizeToShow === false) return null;

  function prizeTitle(prize) {
    return (
      <div>Prêmio{prize.amount > 1 ? 's' : ''} de <b>{numberAsCurrency(prize.value)}</b></div>
    )
  }

  function winnerForNumber(number, prize) {
    return prize.winners.find(item => item.number === number);
  }

  function openWinnerPopup(number, prize) {
    if (prize.show_winners_instantly === false) return;

    const winner = winnerForNumber(number, prize);
    if (winner) {
      setWinner(winner);
      setModalOpen(true);
    }
  }

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  return (
    <div id="instant_prizes">
      <h1 style={{fontSize: '25px'}}>
        Prêmios instantâneos!
      </h1>
      <div style={{textAlign: 'center'}}>
        Esta ação contém números premiados, achou, ganhou!<br/>
        Os números <b>cinza</b> já saíram, compre agora e aproveite!
      </div>
      <Collapse size="large" bordered={false} expandIconPosition="end" expandIcon={({ isActive }) => <img src={isActive ? BlueUp : BlueDown} alt="Expandir" />}>
        {
          campaign.instant_prizes.filter(prize => prize.show_winners_instantly === true).sort((a, b) => a.value - b.value).map(prize => (
            <Panel header={prizeTitle(prize)} key={prize.id}>
              <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly'}}>
                {
                  prize.result_numbers.sort((a, b) => a - b).map((number, index) => (
                    // <span onClick={openWinnerPopup(number, prize)} key={index} className={winnerForNumber(number, prize) ? 'won' : 'lost'}>{number}</span>
                    <span onClick={() => openWinnerPopup(number, prize)} key={index} style={{width: width}} className={`number ${winnerForNumber(number, prize) ? 'won' : 'lost'}`}>{lpad(number, campaign.digits)}</span>
                  ))
                }
              </div>
            </Panel>
          ))
        }
      </Collapse>

      {
        winner &&
          <Modal title="Detalhes" visible={modalOpen} centered onCancel={toggleModal} footer={null}>
            <div>
              <p><b>Nome do comprador:</b> {winner.name}</p>
              <p><b>Telefone:</b> {winner.phone}</p>
              <p><b>CPF:</b> {winner.cpf}</p>
              <p><b>{winner.city}{winner.state && '/'}{winner.state}</b></p>
              <p><b>Hora da compra:</b> {moment(winner.purchase_order_created_at).format('DD/MM/YYYY HH:mm')}</p>
              <p >
                <Ticket ticket={winner.ticket} showCampaign={false} showTime={false} />
              </p>
            </div>
          </Modal>
      }
    </div>
  )
}