import React, { useState, useEffect } from 'react';
import { Flex } from 'antd';
import moment from 'moment';
import { lpad } from '../single-page/utils/index';
import { Link } from 'react-router-dom';

export default function CampaignCountdown({campaign, project}) {
  const drawTime = campaign?.draw_time;
  if (!drawTime) {
    return null;
  } 
  const timeIsUp = moment(drawTime).isBefore(moment());
  const timeEndedToday = moment(drawTime).isSame(moment(), 'day');

  const [timeLeft, setTimeLeft] = useState(moment(drawTime).diff(moment(), 'seconds'));

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(moment(drawTime).diff(moment(), 'seconds'));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="purple_bg" id="countdown" style={{paddingTop: 30}}>
      <div className="container">
        {
          timeIsUp && timeEndedToday ?
          <h1 style={{paddingBottom: 40}}>
            Acompanhe o sorteio <Link to={`/${project.name}/site/acompanhar-sorteio`} style={{color: 'white', textDecoration: 'underline'}}>CLICANDO AQUI!</Link>
          </h1> :
          timeIsUp ?
          <h1 style={{paddingBottom: 40}}>
            Próximo sorteio em breve!
            <br/>
          </h1> :
          <>
            <h1>Próximo sorteio em</h1>
            <Flex justify='center' wrap="wrap" align='center' id="home_timers" style={{paddingBottom: 20}}>
              <div>
                <label className="number" id="days">{lpad(Math.floor(timeLeft / 86400), 2)}</label>
                <br/>
                <label>dias</label>
              </div>
              <div>
                <label className="number" id="hours">{lpad(Math.floor((timeLeft % 86400) / 3600), 2)}</label>
                <br/>
                <label>horas</label>
              </div>
              <div>
                <label className="number" id="minutes">{lpad(Math.floor((timeLeft % 3600) / 60), 2)}</label>
                <br/>
                <label>min.</label>
              </div>
              <div>
                <label className="number" id="seconds">{lpad(timeLeft % 60, 2)}</label>
                <br/>
                <label>seg.</label>
              </div>
            </Flex>
          </>
        }
      </div>
    </div>
  );
}