import React, { useState, useEffect } from 'react';
import { Button, Input, Flex, Space } from 'antd';
import { lpad } from '../single-page/utils/index.js';
import TicketsIcon from '../images/tickets.svg';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { numberAsCurrency } from '../single-page/utils/index.js';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CartContext } from '../single-page/App.js';
import { CampaignContext } from '../single-page/App.js';
import BlueTicketsIcon from '../images/tickets-blue.svg';
import InstantPrizesSummary from './InstantPrizesSummary.js';
import moment from 'moment';
import Swal from 'sweetalert2';
import Cookie from 'js-cookie';

const inputStyle = {
  background: '#f7eb19',
  border: '1px solid #F6EB1C',
  marginLeft: '59px',
  marginRight: '59px',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: 1.5714285714285714,
  height: '42px',
  padding: '9px 15px',
  borderRadius: '10px',
  color: '#2F058B',
};

const buttonStyle = {
  background: '#f7eb19',
  border: '1px solid #F6EB1C',
  color: '#303483',
};

const buyButtonStyle = {
  width: '80%',
  textAlign: 'left',
  margin: '0 auto',
  display: 'block',
  fontWeight: 'bold',
}

const smallButtonStyle = {
  height: '42px',
  width: '42px',
}

export default function CampaignFastBuyControls({ campaign, buyCall, loading, project }) {
  if (!campaign) return null;

  const start_time = moment(campaign.start_time);
  if (!campaign['open?']) {
    if (start_time.isAfter(moment())) {
      return (
        <div className="center">
          <h1>
            As vendas desta campanha começam às {start_time.format('HH:mm')} do dia {start_time.format('DD/MM/YYYY')}.
          </h1>
          <p>
            Fique de olho nas nossas redes sociais para as novidades!
          </p>
        </div>
      );
    } else {
      return (
        <div className="center">
          <h1>
            As vendas desta campanha já foram encerradas.
          </h1>
          <p>
            Fique de olho nas nossas redes sociais para não perder a próxima campanha!
          </p>
        </div>
      );
    }
  }
  const [quantity, setQuantity] = useState(campaign?.default_ticket_selection || 1);
  const [firstClick, setFirstClick] = useState(false);
  
  function numberButtonCallback(number) {
    if (firstClick) {
      safeSetQuantity(parseInt(number.replace('*', '')))
    } else {
      safeSetQuantity(quantity + parseInt(number.replace('*', '')))
    }
  }

  function safeSetQuantity(number) {
    if (number < 1) {
      setQuantity(1);
    } else if (number > campaign?.max_amount) {
      setQuantity(campaign?.max_amount);
    } else if (number < campaign?.min_amount) {
      setQuantity(campaign?.min_amount);
    } else {
      setQuantity(number);
    }
    setFirstClick(false);
  }

  function buyTickets() {
    if (quantity == 0)
      return;

    buyTicketsCall();
  }

  async function tryToIncreaseQuantity() {
    let new_quantity = quantity;
    if (campaign?.max_amount && new_quantity >= campaign?.max_amount) {
      return quantity ;
    }
    if (new_quantity == 1)
      new_quantity = 3;
    if (new_quantity < 4)
      new_quantity = 5;
    else
      new_quantity = Math.ceil((new_quantity + 1) / 10) * 10;
    
    const response = await Swal.fire({
      title: 'Aumente suas chances de ganhar!',
      html: 
        `<p style="font-size: 110%">Você escolheu ${quantity} títulos por ${numberAsCurrency(campaign.ticket_price * quantity)}.\
         <br/>Que tal levar ${new_quantity} títulos por apenas ${numberAsCurrency(campaign.ticket_price * new_quantity)}?<p>`,
      showCancelButton: true,
      confirmButtonText: 'Sim, quero!',
      imageUrl: BlueTicketsIcon,
      imageWidth: 200,
      imageHeight: 100,
      confirmButtonColor: '#253875',
      cancelButtonText: `Não, adicionar apenas ${quantity}`,
      reverseButtons: true,
      allowEscapeKey: false,
      allowOutsideClick: false
    });
    if (response.isConfirmed) {
      return new_quantity;
    } else {
      return quantity;
    }
  }

  async function buyTicketsCall() {
    let new_quantity = await tryToIncreaseQuantity();
    
    buyCall(new_quantity);
  }

  return (
    <div className="fast_buy" style={{padding: '20px 0px'}}>
      <div className="smaller_container">
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <h1 style={{marginBottom: 0}}>
            {campaign.title}
            <br/>
            <small>{campaign.subtitle}</small>
          </h1>
          <div>
            <Button shape="rounded" style={{ ...buttonStyle, ...smallButtonStyle, float: 'left' }} onClick={() => safeSetQuantity(quantity - 1)}>-</Button>
            <Button shape="rounded" style={{ ...buttonStyle, ...smallButtonStyle, float: 'right' }} onClick={() => safeSetQuantity(quantity + 1)}>+</Button>
            <div style={inputStyle}>{quantity}</div>
          </div>
          <Flex justify="space-between" align="center" wrap="wrap" gap="large" className="buyButtons">
            {
              campaign.buttons_array.map(button => (
                <Button onClick={() => numberButtonCallback(button)} key={Math.random()} type="primary" shape="rounded" style={{ ...buttonStyle, height: 'unset', flexGrow: 1}}>
                  <span className="number">{firstClick || '+'}{lpad(button.replace('*', ''), 2)}</span>
                  <br/>
                  <span className="number_label">Títulos</span>
                </Button>
              ))
            }
          </Flex>
          <Button size="large" onClick={buyTickets} loading={loading} type="default" shape="round" style={buyButtonStyle} icon={<img src={TicketsIcon} style={{height: '20px'}} />} >
            <span style={{float: 'right'}}>{numberAsCurrency(campaign.ticket_price * quantity)}</span>
            <span style={{position: 'relative', top: '-4px'}}>Comprar</span>
          </Button>
          {/* Código removido, pois não podemos selecionar cartelas sem saber o número */}
          {
            campaign?.bingo && project &&
              <Link to={`/${project.name}/site/campanhas/${campaign.id}/escolha`}>
                <Button type="link" style={{width: '100%'}}>
                  Ou clique aqui para escolher suas cartelas
                </Button>
              </Link>
          }
          <InstantPrizesSummary campaign={campaign} />
          {
            campaign?.description_body &&
              <div className="left rounded_square" style={{backgroundColor: '#E8ECFD', maxHeight: 190, overflowY: 'auto'}}>
                <div dangerouslySetInnerHTML={{__html: campaign.description_body}} style={{maxHeight: 130, overflowY: 'auto', margin: 10}} />
              </div>
          }
        </Space>
      </div>
    </div>
  );
}