import React, { useState, useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify'
import { UserOutlined, MobileOutlined, LockOutlined, ContactsOutlined, HomeOutlined, EnvironmentOutlined } from '@ant-design/icons';
import MaskedInput from '../../components/MaskedInput';
import { Input, Button, Form, Layout, Select } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import { AffiliateContext } from '../App.js';
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';

const { Content } = Layout;

export default function AffiliateSignUp({isMobile, project}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const disabled = false;
  const numberRef = useRef();
  const { affiliate, setAffiliate } = useContext(AffiliateContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const [cities, setCities] = useState([]);

  function sign_in(formData) {
    document.activeElement.blur();
    setLoading(true);
    
    axios.post('/affiliates', { affiliate: { ...formData } })
      .then(function(response){
        window.location.href = `/revendedor/site?flash_notice=Agora, confirme o seu telefone para prosseguir.`
      })
      .catch(function(error){
        toast.error('Erro ao processar solicitação.');
        if (error.response.status == 422){
          setErrors(error.response.data.errors);
        }
      })
      .then(function() {
        setLoading(false);
      });
  }

  function zipCodeSearch(e) {
    const zipcode = e.target.value.replace(/[^0-9]/g, '');

    if (zipcode.length == 8) {
      axios.get(`https://viacep.com.br/ws/${zipcode}/json/`)
        .then(function(response){
          form.setFieldsValue(
            {
              address: response.data.logradouro, neighborhood: response.data.bairro, city: response.data.localidade, state: response.data.uf
            }
          );
          numberRef.current.focus();
        })
        .catch(function(error){
          console.log(error);
          setErrors({zipcode: ['CEP não encontrado']});
        });
    }
  }

  useEffect(() => {
    if (!affiliate || !affiliate.cpf) {
      history.push(`/${project.name}/site/revendedor/cpf`);
    } else {
      form.setFieldsValue({ cpf: affiliate.cpf });
      setTimeout(checkCpfResultTask, 500);
    }
  }, []);

  function checkCpfResultTask() {
    axios.get('/affiliates/cpf/check_results', { params: { cpf: affiliate.cpf } })
      .then(function(response){
        if (response.data.status == 'waiting') {
          setTimeout(checkCpfResultTask, 2000);
        } else if (response.data.status == 'less_than18') {
          toast.error('Você precisa ter mais de 18 anos para se cadastrar.');
          setErrors({ cpf: ['Você precisa ter mais de 18 anos para se cadastrar.'] });
          setTimeout(() => history.push('/site/revendedor/cpf'), 5000);
        } else if (response.data.status == 'invalid') {
          setErrors({ cpf: ['CPF inválido.'] });
          toast.error('CPF inválido.');
          setTimeout(() => history.push('/site/revendedor/cpf'), 5000);
        } else {
          form.setFieldsValue({ name: response.data.name });
          setLoading(false);
        }
      })
      .catch(function(error) {
        setTimeout(checkCpfResultTask, 5000);
      });
  }

  function loadCities(value) {
    setCities([]);
    axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${value}/municipios`)
      .then(function(response){
        setCities(response.data);
      })
      .catch(function(error){
        console.log(error);
      });
  }

  function handleStateChange(value) {
    form.setFieldsValue({ city: undefined });
    loadCities(value);
  }

  return (
    <>
      <Content className="smallest_container">
        <h1>
          Seja um revendedor Nacional CAP!
        </h1>
        <p className="center">
          <b>Chegou a hora de você aumentar sua renda.</b><br/>
          Venda nacional Cap de casa ou de onde estiver.<br/>
          Preencha o formulário e comece a vender.<br/>
          Não perca essa chance!
        </p>
        <p className="center">
          Precisamos identificá-lo para prosseguirmos.
        </p>
        <Form form={form} layout="vertical" name="sign_up" className="login-form" initialValues={{ remember: true }} onFinish={sign_in}>
          <Form.Item name="cpf" rules={[{ required: true }]} label="CPF" required hasFeedback={!!errors['cpf']} validateStatus={errors['cpf'] && 'error'} help={errors['cpf']?.join(', ')}>
            <MaskedInput name="phone" form={form} disabled={true} prefix={<UserOutlined className="site-form-item-icon" />}  mask="999.999.999-99" placeholder="000.000.000-00" />
          </Form.Item>
          <Form.Item name="email" rules={[{ required: true }]} required label="E-mail" hasFeedback={!!errors['email']} validateStatus={errors['email'] && 'error'} help={errors['email']?.join(', ')}>
            <Input autoFocus prefix={<ContactsOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="phone" rules={[{ required: true }]} required label="Telefone" hasFeedback={!!errors['phone']} validateStatus={errors['phone'] && 'error'} help={errors['phone']?.join(', ')}>
            <MaskedInput name="phone" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<MobileOutlined className="site-form-item-icon" />} mask="(99) 99999 9999" placeholder="(99) 99999 9999" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true }]} required label="Crie uma Senha" hasFeedback={!!errors['password']} validateStatus={errors['password'] && 'error'} help={errors['password']?.join(', ')}>
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
          </Form.Item>
          <Form.Item name="password_confirmation" rules={[{ required: true }]} required label="Confirme sua senha" hasFeedback={!!errors['password_confirmation']} validateStatus={errors['password_confirmation'] && 'error'} help={errors['password_confirmation']?.join(', ')}>
            <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" />
          </Form.Item>
          <Form.Item name="name" rules={[{ required: true }]} required label="Nome" hasFeedback={!!errors['name']} validateStatus={errors['name'] && 'error'} help={errors['name']?.join(', ')}>
            <Input prefix={<ContactsOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="zipcode" rules={[{ required: true }]} required onBlur={zipCodeSearch} label="CEP" hasFeedback={!!errors['zipcode']} validateStatus={errors['zipcode'] && 'error'} help={errors['zipcode']?.join(', ')}>
            <MaskedInput name="zipcode" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<MobileOutlined className="site-form-item-icon" />} type="phone" mask="99999-999" placeholder="99999-999" />
          </Form.Item>
          <Form.Item name="address" rules={[{ required: true }]} required label="Endereço" hasFeedback={!!errors['address']} validateStatus={errors['address'] && 'error'} help={errors['address']?.join(', ')}>
            <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="number" rules={[{ required: true }]} required label="Número" hasFeedback={!!errors['number']} validateStatus={errors['number'] && 'error'} help={errors['number']?.join(', ')}>
            <Input ref={numberRef} prefix={<HomeOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="complement" label="Complemento" hasFeedback={!!errors['complement']} validateStatus={errors['complement'] && 'error'} help={errors['complement']?.join(', ')}>
            <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="neighborhood" rules={[{ required: true }]} required label="Bairro" hasFeedback={!!errors['neighborhood']} validateStatus={errors['neighborhood'] && 'error'} help={errors['neighborhood']?.join(', ')}>
            <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="city" rules={[{ required: true }]} required label="Cidade" hasFeedback={!!errors['city']} validateStatus={errors['city'] && 'error'} help={errors['city']?.join(', ')}>
            <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <Form.Item name="state" rules={[{ required: true }]} required label="Estado" hasFeedback={!!errors['state']} validateStatus={errors['state'] && 'error'} help={errors['state']?.join(', ')}>
            <Input prefix={<HomeOutlined className="site-form-item-icon" />} />
          </Form.Item>
          <p style={{ fontSize: '14px', paddingTop: '10px' }}>
            Ao clicar em "Criar conta", você concorda com os nossos <a href='/site/termos' target='_blank'>Termos e condições</a> e nossa <a href='/site/politicas' target='_blank'>Política de privacidade</a>.
          </p>
          <Form.Item>
            <Button shape="round" block type="primary" htmlType="submit" disabled={disabled} loading={loading} className="login-form-button">
              Criar conta
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
}