import React, { useState } from 'react';
import { Button } from 'antd';
import { lpad } from '../single-page/utils/index.js';

export default function ItemToChoose({item, index, addTicketToCart}) {
  const [loading, setLoading] = useState(false);

  return (
    <div className="item_to_choose rounded_square">
      <p><b>Título: </b>{lpad(item.number, 8)}</p>
      <p>
        {
          item.numbers.map(number => (
            <span key={number} className="bingo_ball">{lpad(number, 2)} </span>
          ))
        }
      </p>
      <Button loading={loading} shape="round" size="small" type="primary" onClick={() => addTicketToCart(index, setLoading)}>Escolher título</Button>
    </div>
  )
}