import React from 'react';
import { Steps } from 'antd';

export default function PurchaseOrderSteps({step = 0, isMobile = false}) {
  if (window.location.pathname.includes('/site/acesso') || isMobile) {
    return null;
  }

  return (
    <div className="smaller_container" style={{paddingTop: 20}}>
      <Steps current={step} items={[
        {
          title: 'Carrinho'
        },
        {
          title: 'Identificação'
        },
        {
          title: 'Pagamento'
        },
      ]} />
    </div>
  );
}