import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';
import axios from 'axios';
import ArrowIcon from '../../../images/arrow.svg';

const rootStyle = {
  marginTop: '20px',
  maxWidth: '800px',
  marginLeft: 'auto',
  marginRight: 'auto',
  backgroundColor: '#253875'
}

const imgStyle = {
  height: '100%',
  width: '100%',
  objectFit: 'cover'
}

const detailsStyle = {
  color: 'white',
  padding: '10px',
  textAlign: 'center'
}

export default function OrderAd({order}) {
  if (!order || order.id == undefined) return (<></>);

  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    axios.get(`/api/int/v1/campaigns/ad.json?purchase_order_id=${order.id}`)
      .then(response => {
        setCampaign(response.data);
      })
      .catch(error => {
        console.error(error);
      })
  }, []);

  return (
    <>
      {
        campaign &&
          <div style={rootStyle}>
            <Link to={`/${campaign.project.name}/site/campanhas/${campaign.id}`}>
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{lineHeight: 0}}>
                  <img src={campaign.web_banner_url} style={imgStyle} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} style={detailsStyle}>
                  <h2 style={{color: '#F6E93C', margin: '0 0 5px 0'}}>{campaign.title}</h2>
                  <h3 style={{margin: '0 0 10px 0', fontSize: 14}}>{campaign.subtitle}</h3>
                  <span className="roundedButton" style={{ backgroundColor: '#F6EB1C', color: '#303483' }}>
                    Compre Agora
                    <img src={ArrowIcon} style={{ height: 12 }} />
                  </span>
                </Col>
              </Row>
            </Link>
          </div>
      }
    </>
  );
}