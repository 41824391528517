import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { MobileOutlined, LockOutlined } from '@ant-design/icons';
import MaskedInput from '../../components/MaskedInput';
import { Input, Button, Form, Layout } from 'antd';
import axios from 'axios';
import { useHistory } from 'react-router-dom'
import { UserContext } from '../App.js';
import PurchaseOrderSteps from '../../components/PurchaseOrderSteps';

const { Content } = Layout;

export default function PhoneConfirmation({isMobile, project, app_settings}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const [form] = Form.useForm();
  const [resetForm] = Form.useForm();
  const history = useHistory();
  const [sentOnce, setSentOnce] = useState(false);
  const otpRef = useRef();
  const [changePhone, setChangePhone] = useState(false);
  const [sendButtonEnabled, setSendButtonEnabled] = useState(true);

  function request_token(formData) {
    if (!sendButtonEnabled) return;

    document.activeElement.blur();
    setSendButtonEnabled(false);
    setLoading(true);
    axios.post('/api/int/v1/phone/send_token')
      .then(function(response){
        setSentOnce(true);
        toast.success(`Código enviado para o número ${response.data.phone}`);
        setTimeout(() => {
          setSendButtonEnabled(true);
        }, 60000);
      })
      .catch(function(error){
        toast.error('Erro ao processar solicitação.');
      })
      .then(function() {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (sentOnce) {
      if (otpRef.current.nativeElement.children.length === 0) return;

      for(let inputIndex in otpRef.current.nativeElement.children) {
        let input = otpRef.current.nativeElement.children[inputIndex];
        if (!input.attributes) continue;
        input.inputMode = 'tel';
        input.pattern = '[0-9]*';
        input.type = 'tel';
      }
      otpRef.current.nativeElement.children[0].focus();
    }
  }, [sentOnce]);

  // TODO: preciso impedir o usuário de pedir outro token em menos de 2 minutos.
  // TODO: preciso levar em conta o CPF do cara nessa requisição, porque nosso token SMS é fraco.

  function confirm_phone(formData) {
    document.activeElement.blur();
    setLoading(true);
    axios.post('/api/int/v1/phone/confirm', { token: formData.reset_password_token })
      .then(function(){
        if (app_settings.root_to_login && app_settings.sign_up_success_message) {
          window.location.href = `/${project.name}/site/minha-conta?flash_notice=Telefone confirmado`
        } else {
          window.location.href = `/${project.name}/site?flash_notice=Telefone confirmado`
        }
      })
      .catch(function(error){
        toast.error(error.response.data.error);
      })
      .then(function() {
        setLoading(false);
      });
  }

  function handleChangePhone() {
    setChangePhone(true);
    setSendButtonEnabled(false);
  }

  function cancelPhoneChange() {
    setChangePhone(false);
    setSendButtonEnabled(true);
    form.setFieldsValue({ phone: '' });
  }

  function submitNewPhone() {
    if (!form.getFieldValue('phone')) return;

    axios.put('/customers', { customer: { phone: form.getFieldValue('phone') } })
      .then(function(response){
        user.phone = form.getFieldValue('phone');
        setUser(user);
        setErrors({});
        toast.success('Telefone atualizado com sucesso');
        setSendButtonEnabled(true);
        setChangePhone(false);
      })
      .catch(function(error){
        setErrors(error.response.data.errors);
        toast.error('Erro ao processar solicitação.');
      });

  }

  useEffect(() => {
    if (!user || !user.cpf) {
      history.push(`/${project.name}/site/cpf`);
    } else {
      form.setFieldsValue({ cpf: user.cpf, name: user.name });
      resetForm.setFieldsValue({ cpf: user.cpf });
    }
  }, []);

  return (
    <>
      <PurchaseOrderSteps step={1} isMobile={isMobile} />
      <Content className="smallest_container">
        <h1>
          Confirme o seu telefone para continuar
        </h1>
        <Form form={form} layout="vertical" name="request_token" className="login-form" initialValues={{ remember: true }} onFinish={request_token}>
          {
            changePhone ?
              <div style={{textAlign: 'right', position: 'relative', top: 20, zIndex: 999}}>
                <span onClick={submitNewPhone}><a>Salvar novo número</a></span> / <span onClick={cancelPhoneChange}><a>Cancelar</a></span>
              </div>
              :
              <div style={{textAlign: 'right', position: 'relative', top: 20, zIndex: 999}} onClick={handleChangePhone}><a>Alterar telefone</a></div>
          }
          <Form.Item name="phone" rules={changePhone && [{ required: true }]} required label="Telefone" hasFeedback={!!errors['phone']} validateStatus={errors['phone'] && 'error'} help={errors['phone']?.join(', ')}>
            <MaskedInput disabled={!changePhone} name="phone" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<MobileOutlined className="site-form-item-icon" />} mask={"(99) 99999 9999"} placeholder={user.phone} />
          </Form.Item>
          <Form.Item>
            <Button disabled={!sendButtonEnabled} shape="round" block type="primary" htmlType="submit" loading={loading} className="login-form-button">
              {!sentOnce ? 'Enviar' : 'Reenviar'} código
            </Button>
          </Form.Item>
        </Form>
        {
          sentOnce &&
          <Form layout="vertical" form={resetForm} name="normal_login" className="login-form" initialValues={{ remember: true }} onFinish={confirm_phone}>
            <Form.Item name="cpf">
              <Input type="hidden" />
            </Form.Item>
            <p className="center">Por favor, digite o código de 6 dígitos que foi enviado para o seu número para prosseguirmos</p>
            <Form.Item name="reset_password_token" rules={[{ required: true }]} required label="Código" hasFeedback={!!errors['reset_password_token']} validateStatus={errors['reset_password_token'] && 'error'} help={errors['reset_password_token']?.join(', ')}>
              <Input.OTP ref={otpRef} size="large" length={6} />
            </Form.Item>
            <Form.Item>
              <Button shape="round" block type="primary" htmlType="submit" loading={loading} className="login-form-button">
                Confirmar número
              </Button>
            </Form.Item>
          </Form>
        }
      </Content>
    </>
  );
}