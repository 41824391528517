import React from 'react';
import Ticket from '../../../components/Ticket';
import { Flex } from 'antd';

export default function OrderTickets({order}) {
  // debugger;
  return (
    <div>
      <h1>Títulos do pedido</h1>
      <Flex wrap="wrap" justify="center" align="center" style={{marginTop: 20}} justify="space-evenly">
        {
          order.tickets.map((ticket) => (
            <Ticket key={ticket.id} ticket={ticket} showTime={false} />
          ))
        }
      </Flex>
    </div>
  );
}