import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Carousel } from 'antd';
import CampaignFastBuy from '../../components/CampaignFastBuy';


export default function CampaignDetails({currentUser, project, isMobile}) {
  let { campaignId } = useParams();
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    axios.get(`/api/int/v1/campaigns/${campaignId}`)
      .then(response => {
        setCampaign(response.data);
      })
      .catch(error => {
        toast.error('Erro ao carregar campanha');
      });
  }, []);


  return (
    <div>
      <Carousel autoplay>
        {
          campaign && 
            <div key={campaign.id}>
              <img src={isMobile ? campaign.mobile_banner_url : campaign.web_banner_url} alt={campaign.title} className="campaign-desktop-banner" />
            </div>
        }
      </Carousel>
      <div className="container">
        <CampaignFastBuy campaign={campaign} currentUser={currentUser} project={project} />
      </div>
    </div>
  );
}