import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { List, Button } from 'antd';
import Ticket from '../../../components/Ticket';
import { Link } from 'react-router-dom';
import SadIcon from '../../../images/sad.svg';

export default function MyTickets({project, app_settings}) {
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  function fetchTickets() {
    setLoading(true);
    axios.get(`/api/int/v1/tickets/${project.name}`, { params: { page: page, current_only: true } })
      .then(function(res){
        setList([...list, ...res.data.tickets]);
        setPage(page + 1);
        setHasMore(res.data.next_page);
      })
      .catch(function(error){
        console.log(error);
        toast.error('Erro ao carregar títulos');
      })
      .finally(function(){
        setLoading(false);
        setInitLoading(false);
      });
  }

  useEffect(() => {
    fetchTickets();
  }, []);

  function emptyList() {
    if (app_settings.root_to_login && app_settings.sign_up_success_message) {
      return (
        <div className="center" style={{paddingTop: 40}}>
          <h1>
            {app_settings.sign_up_success_message}
          </h1>
        </div>
      );
    } else {
      return (
        <div className="center" style={{paddingTop: 40}}>
          <h1>
            Você não tem nenhum título da campanha atual. Verifique o histórico completo para campanhas anteriores.
            <img src={SadIcon} style={{width: 30, marginLeft: 10, verticalAlign: 'middle'}} />
          </h1>
          <p>
            Participe do próximo sorteio, compre seus títulos clicando no botão agora:
          </p>
          <p>
            <Link to={`/${project.name}/site/compre-agora`}>
              <Button size="medium" shape="round" type="primary">
                Compre Agora
              </Button>
            </Link>
          </p>
        </div>
      );
    }
     
  }

  return (
    <div style={{paddingBottom: 40}}>
      <List
        locale={{emptyText: emptyList()}}
        className="meus-titulos"
        loading={initLoading}
        itemLayout="horizontal"
        loadMore={!initLoading && !loading && hasMore && <div className="center"><Button type="primary" shape="round" size="small" onClick={fetchTickets}>Carregar mais</Button></div>}

        dataSource={list}
        renderItem={(item) => (
          <List.Item>
            <Ticket ticket={item} />
          </List.Item>
        )}
      />
    </div>
  );
}