import React from 'react';
import { Collapse } from 'antd';
import FaqBulletIcon from '../images/faq_bullet.svg';
import PlusIcon from '../images/plus.svg';
import MinusIcon from '../images/minus.svg';

const { Panel } = Collapse;

export default function FaqCollapse({frequently_asked_questions}) {
  return (
    <Collapse size="large" bordered={false} expandIconPosition="end" expandIcon={({ isActive }) => <img src={isActive ? MinusIcon : PlusIcon} alt="Expandir" />}>
      {
        frequently_asked_questions.map(faq => (
          <Panel header={<div>{faq.question}</div>} key={faq.id}>
          {/* <Panel header={<div><img className="bullet" src={FaqBulletIcon} />{faq.question}</div>} key={faq.id}> */}
            <p>{faq.answer}</p>
          </Panel>
        ))
      }
    </Collapse>
  );
}