import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Button, Flex } from 'antd';
import SmallCampaign from '../../components/SmallCampaign';

export default function Campaigns({project}) {
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [showMore, setShowMore] = useState(true);

  function fetchCampaigns() {
    setLoading(true);
    axios.get(`/api/int/v1/${project.name}/campaigns.json?page=${page}`)
      .then(function (response) {
        setCampaigns([...campaigns, ...response.data.campaigns]);
        setPage(page + 1);
        setShowMore(response.data.next_page)
      })
      .catch(function (error) {
        toast.error('Erro ao carregar campanhas');
      })
      .then(function () {
        setLoading(false);
      });
  }

  useEffect(function () {
    fetchCampaigns();
  }, []);

  return (
    <div className="container">
      <h1>Todas as Campanhas</h1>
      <Flex justify="space-evenly" wrap="wrap" align="center" gap="large" >
        {
          campaigns.map(campaign => (
            <SmallCampaign key={campaign.id} campaign={campaign} project={project} />
          ))
        }
      </Flex>
      <p style={{marginTop: 40}} className="center">
        {
          showMore && 
          <Link to={`/${project.name}/site/campanhas`}>
            <Button loading={loading} shape="round" size="small" type="default">Ver mais</Button>
          </Link>
        }
      </p>
    </div>
  );
}