import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import YoutubeVideo from '../../../components/YoutubeVideo';



const rootStyle = {
  padding: '50px 0',
  color: 'white',
  textAlign: 'center',
};

export default function WinnersHomeIndex({winners, isMobile, project}) {

  if (!winners || winners.length === 0) {
    return null;
  }
  const showedWinners = isMobile ? winners.slice(0, 1) : winners;

  return (
    <div className="purple_bg" style={rootStyle}>
      <div className="container">
        <h1>Conheça os nossos ganhadores</h1>
        <div>
          <Row gutter={[16, 16]}>
            {
              showedWinners.map(winner => (
                <Col xs={24} sm={24} md={8} lg={8} xl={8} key={winner.id}>
                  <YoutubeVideo videoId={winner.video_id} />
                </Col>
              ))
            }
          </Row>
          <p style={{marginTop: 40}}>
            <Link to={`/${project.name}/site/depoimentos`}>
              <Button shape="round" size="small" type="primary">Ver mais</Button>
            </Link>
          </p>
          <h1>Você pode ser o próximo!</h1>
        </div>
      </div>
    </div>
    
  );
}