import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Carousel, Skeleton, Space } from 'antd';
import { toast } from 'react-toastify';
import CampaignCountdown from '../../components/CampaignCountdown';
import CampaignFastBuy from '../../components/CampaignFastBuy';
import CampaignHomeIndex from './landing/CampaignHomeIndex';
import ResultHomeIndex from './landing/ResultHomeIndex';
import WinnersHomeIndex from './landing/WinnersHomeIndex';
import FaqHomeIndex from './landing/FaqHomeIndex';


export default function Landing({isMobile, currentUser, project}){
  const [loading, setLoading] = useState(true);
  const [homeData, setHomeData] = useState({
    default_campaign: null,
    campaigns: [],
    results: [],
    winners: [],
    faq: [],
  });

  useEffect(() => {
    axios.get(`/api/int/v1/home/${project.name}`)
      .then(response => {
        setHomeData(response.data);
      })
      .catch(error => {
        toast.error('Estamos enfrentando alguns problemas técnicos, por favor retorne em instantes');
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div id="landing_page">
      {
        loading ?
          <>
            <div className="center">
              <Skeleton.Image active={true} style={{width: '100vw', height: '300px'}} />
              <Skeleton active={true} paragraph={{rows: 4}} rows={2} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <br/>
              <Skeleton.Button active={true} shape="round" size="large" style={{width: 180}} />
              <Skeleton active={true} paragraph={{rows: 4}} rows={2} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
              <Skeleton.Button active={true} size="large" style={{margin: 10}} />
            </div>
          </>
          :
          <>
            <Carousel autoplay>
              {
                homeData.default_campaign && 
                  <div key={homeData.default_campaign.id}>
                    <img src={isMobile ? homeData.default_campaign.mobile_banner_url : homeData.default_campaign.web_banner_url} alt={homeData.default_campaign.title} className="campaign-desktop-banner" />
                  </div>
              }
              {/* {
                homeData.campaigns && homeData.campaigns.map(campaign => (
                  <div key={campaign.id}>
                    <img src={isMobile ? campaign.mobile_banner_url : campaign.web_banner_url} alt={campaign.title} />
                  </div>
                ))
              } */}
            </Carousel>
            <CampaignFastBuy campaign={homeData.default_campaign} currentUser={currentUser} project={project} />
            <CampaignCountdown campaign={homeData.default_campaign} project={project} />
            <CampaignHomeIndex campaigns={homeData.campaigns} project={project} />
            <ResultHomeIndex results={homeData.results} project={project} />
            <WinnersHomeIndex winners={homeData.winners} isMobile={isMobile} project={project} />
            <FaqHomeIndex frequently_asked_questions={homeData.faq} project={project} />
          </>    
      }
    </div>
  )
}