import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Icon, { HomeFilled, TagsFilled, UserOutlined, YoutubeFilled, TrophyFilled, DollarOutlined } from '@ant-design/icons';
import { TabBar } from 'antd-mobile';

function DollarSvg() {
  return (
    <svg width="60" height="60" viewBox="0 0 87 87" fill="#253875" xmlns="http://www.w3.org/2000/svg">
      <circle cx="43.5" cy="43.5" r="43.5" fill="#253875"/>
      <path d="M32.592 50.5748C32.9374 54.9928 36.3635 58.4336 42.5068 58.8412V62.1014H45.2331V58.818C51.5946 58.3642 55.3115 54.9002 55.3115 49.8709C55.3115 45.3001 52.467 42.9429 47.3824 41.7203L45.2331 41.2016V32.3239C47.9595 32.6388 49.6952 34.1578 50.1133 36.2603H54.8935C54.5481 32.0044 50.9675 28.6747 45.2331 28.3135V25.0532H42.5068V28.3829C37.0769 28.9248 33.3736 32.2545 33.3736 36.9642C33.3736 41.1321 36.1272 43.7811 40.7029 44.8694L42.5068 45.3232V54.7427C39.7123 54.312 37.863 52.7514 37.4449 50.5748H32.592ZM42.4841 40.544C39.8032 39.9095 38.3492 38.6175 38.3492 36.6725C38.3492 34.4959 39.9168 32.8658 42.5068 32.3888V40.5394L42.4841 40.544ZM45.6285 46.0688C48.8864 46.8376 50.3905 48.0833 50.3905 50.283C50.3905 52.7931 48.5184 54.5158 45.2331 54.8307V45.9762L45.6285 46.0688Z" fill="white"/>
      <path className="circle" d="M43.5 75.4654C34.7976 75.4654 26.4516 72.05 20.2981 65.9706C14.1445 59.8912 10.6875 51.6458 10.6875 43.0482C10.6875 34.4506 14.1445 26.2052 20.2981 20.1258C26.4516 14.0464 34.7976 10.631 43.5 10.631C52.2024 10.631 60.5484 14.0464 66.7019 20.1258C72.8555 26.2052 76.3125 34.4506 76.3125 43.0482C76.3125 51.6458 72.8555 59.8912 66.7019 65.9706C60.5484 72.05 52.2024 75.4654 43.5 75.4654ZM43.5 80.0964C53.4456 80.0964 62.9839 76.1931 70.0165 69.2452C77.0491 62.2973 81 52.874 81 43.0482C81 33.2224 77.0491 23.799 70.0165 16.8512C62.9839 9.90328 53.4456 6 43.5 6C33.5544 6 24.0161 9.90328 16.9835 16.8512C9.95088 23.799 6 33.2224 6 43.0482C6 52.874 9.95088 62.2973 16.9835 69.2452C24.0161 76.1931 33.5544 80.0964 43.5 80.0964Z" fill="white"/>
      <path className="circle" d="M43.9521 68.2762C37.4244 68.2762 31.164 65.6182 26.5482 60.8871C21.9324 56.1559 19.3393 49.739 19.3393 43.0481C19.3393 36.3572 21.9324 29.9404 26.5482 25.2092C31.164 20.478 37.4244 17.8201 43.9521 17.8201C50.4798 17.8201 56.7401 20.478 61.3559 25.2092C65.9717 29.9404 68.5648 36.3572 68.5648 43.0481C68.5648 49.739 65.9717 56.1559 61.3559 60.8871C56.7401 65.6182 50.4798 68.2762 43.9521 68.2762ZM43.9521 70.5696C47.4781 70.5696 50.9696 69.8578 54.2272 68.4747C57.4848 67.0916 60.4448 65.0644 62.9381 62.5088C65.4313 59.9532 67.4091 56.9192 68.7585 53.5802C70.1078 50.2411 70.8023 46.6623 70.8023 43.0481C70.8023 39.434 70.1078 35.8552 68.7585 32.5161C67.4091 29.177 65.4313 26.1431 62.9381 23.5875C60.4448 21.0319 57.4848 19.0046 54.2272 17.6216C50.9696 16.2385 47.4781 15.5266 43.9521 15.5266C36.8309 15.5266 30.0015 18.4262 24.9661 23.5875C19.9307 28.7488 17.1018 35.749 17.1018 43.0481C17.1018 50.3473 19.9307 57.3475 24.9661 62.5088C30.0015 67.6701 36.8309 70.5696 43.9521 70.5696Z" fill="white"/>
    </svg>
  )
}

function DollarIcon(props){
  return <Icon component={DollarSvg} {...props} />
};

export default function MobileMenu({currentUser, isMobile, project}) {
  if (!isMobile) return null;
  const location = useLocation();
  const history = useHistory();
  const { pathname } = location;

  return (
    <TabBar safeArea={true} activeKey={pathname} onChange={value => history.push(value)} style={{ zIndex: 99999, overflow: 'visible', position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#253875', color: 'white' }}>
      <TabBar.Item key={`/${project.name}`} icon={<HomeFilled />} title="Início" />
      <TabBar.Item key={`/site/revendedor/cpf`} icon={<TagsFilled />} title="Revender" /> :
      <TabBar.Item key={`/${project.name}/site/compre-agora`} icon={<DollarIcon style={{position: 'relative', bottom: '30px'}} />} title="Comprar" />
      <TabBar.Item key={`/${project.name}/site/acompanhar-sorteio`} icon={<YoutubeFilled />} title="Sorteios" />
      <TabBar.Item key={`/${project.name}/site/resultados`} icon={<TrophyFilled />} title="Resultados" />
    </TabBar> 
  )
}