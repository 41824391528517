import React from 'react';
import { Space, Row, Col } from 'antd';
import BeneficiarioIcon from '../../images/beneficiario.png'

export default function AboutUs({site_texts}) {

  return (
    <div className="smaller_container" style={{padding: '50px 20px'}}>
      <Space direction="vertical">
        <div dangerouslySetInnerHTML={{ __html: site_texts.about.body }} />
        {
          site_texts.about_image &&
            <div style={{textAlign: 'center'}}>
              <img src={site_texts.about_image} style={{width: '80%'}} />
            </div>
        }
        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{  alignContent: 'center', alignItems: 'center', justifyContent: 'space-evenly'}}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <div className="rounded_square center" style={{margin: 10}}>
              <p>
                <span className="purple" style={{fontSize: 26, fontWeight: 'bold'}}>23.492.123</span>
                <br/><br/>
                Atendimentos ao ano
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <div className="rounded_square center" style={{margin: 10}}>
              <p>
                <span className="purple" style={{fontSize: 26, fontWeight: 'bold'}}>1.600.000</span>
                <br/><br/>
                Número de assistidos
              </p>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8}>
            <div className="rounded_square center" style={{margin: 10}}>
              <p>
                <span className="purple" style={{fontSize: 26, fontWeight: 'bold'}}>2.249</span>
                <br/><br/>
                Unidades em território nacional
              </p>
            </div>
          </Col>
        </Row> */}
      </Space>
    </div>
  )

}