import React, { useState, useEffect } from 'react';
import { Button, Input, Flex, Space } from 'antd';
import TicketsIcon from '../images/tickets.svg';
import axios from 'axios';
import { numberAsCurrency } from '../single-page/utils/index.js';
import { toast } from 'react-toastify';

const inputStyle = {
  background: '#F4F4F4',
  border: '1px solid #BBBBBB',
  marginLeft: '59px',
  marginRight: '59px',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: 1.5714285714285714,
  height: '42px',
  padding: '9px 15px',
  borderRadius: '10px',
  color: '#2F058B',
};

const buttonStyle = {
  background: '#F4F4F4',
  border: '1px solid #BBBBBB',
  color: '#2F058B',
};

const buyButtonStyle = {
  textAlign: 'left',
  margin: '0 auto',
  display: 'block',
  fontWeight: 'bold',
}

const smallButtonStyle = {
  height: '42px',
  width: '42px',
}

export default function CampaignSmallBuy({ campaign, setCart, setCartCount }) {
  const [quantity, setQuantity] = useState(1);
  const [loading, setLoading] = useState(false);
  console.log("small buy");
  
  if (!campaign) {
    return null;
  }

  function safeSetQuantity(number) {
    if (number < 1) {
      setQuantity(1);
    } else if (number > campaign?.max_amount) {
      setQuantity(campaign?.max_amount);
    } else {
      setQuantity(number);
    }
  }

  function buyTickets() {
    setLoading(true);
    axios.post(`/api/int/v1/shopping_carts/add_next_items`, { campaign_id: campaign.id, amount: quantity })
      .then(response => {
        setCartCount(response.data.items.length);
        setCart(response.data);
        setQuantity(1);
      })
      .catch(error => {
        if (error.response?.status == 422 && error?.response?.data?.error == 'sold_out') {
          toast.error('Ops, parece que acabaram os títulos disponíveis para essa campanha. ');
        } else if (error.response?.status == 422 && error?.response?.data?.error == 'cart_full_error') {
          toast.error('Valor máximo de títulos no carrinho atingido');  
        } else if (error.response?.status == 422 && error?.response?.data?.error == 'closed') {
          toast.error('Ops, parece que as vendas para essa campanha foram encerradas.');
        } else {
          toast.error('Erro ao processar compra, tente em instantes');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="fast_buy" style={{padding: '20px 0px'}}>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <p style={{margin: 0}} className="purple">
          <b>Adicione mais títulos e aumente suas chances!</b>
        </p>
        <div>
          <Button shape="rounded" style={{ ...buttonStyle, ...smallButtonStyle, float: 'left' }} onClick={() => safeSetQuantity(quantity - 1)}>-</Button>
          <Button shape="rounded" style={{ ...buttonStyle, ...smallButtonStyle, float: 'right' }} onClick={() => safeSetQuantity(quantity + 1)}>+</Button>
          <div style={inputStyle}>{quantity}</div>
        </div>
        <Button size="small" onClick={buyTickets} loading={loading} type="default" shape="round" style={buyButtonStyle} icon={<img src={TicketsIcon} style={{height: '20px'}} />} >
          <span style={{float: 'right'}}>&nbsp;{numberAsCurrency(campaign.ticket_price * quantity)}</span>
          <span style={{position: 'relative', top: '-4px'}}>Adicionar + {quantity} título(s) por</span>
        </Button>
      </Space>
    </div>
  );
}