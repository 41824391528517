import React, { useState, useEffect } from 'react';
import ClockIcon from '../../../images/clock.svg';
import moment from 'moment';
import { Row, Col, Button, QRCode } from 'antd';
import { copyToClipboard } from '../../utils';
import { toast } from 'react-toastify';
import DotLogo from '../../../images/dot-logo.png';


export default function OrderQrCode({order}) {
  if (order?.status !== 'waiting_payment' || moment(order?.qr_code_received_at).isBefore(moment().subtract(15, 'minutes')))
    return null;

  function minutesLeft(order) {
    const now = moment();
    const expires_at = moment(order.qr_code_received_at).add(15, 'minutes');
    if (expires_at.isBefore(now)) return '00:00';
    return moment.utc(moment.duration(expires_at.diff(now)).asMilliseconds()).format('mm:ss');
  }

  const [timeLeft, setTimeLeft] = useState(minutesLeft(order));

  useEffect(() => {
    if (order) {
      const interval = setInterval((order) => {
        var tl = minutesLeft(order);
        if (tl === '00:00') {
          window.location.reload();
        }
        setTimeLeft(tl);
      }, 1000, order);
      return () => clearInterval(interval);
    }
  }, [order]);

  function clipboardQRCode() {
    try {
      copyToClipboard(order.qr_code_text);
      toast.success('Código copiado');
    } catch (err) {
      toast.error('Erro ao copiar código. Selecione o código acima do botão e copie manualmente.');
    }
  }

  return (
    <div id="order_qr_code">
      <h1 className="left">Finalize sua transação</h1>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <QRCode size={300} iconSize={75} value={order?.qr_code_text} icon={DotLogo} errorLevel='H' style={{width: '100%', height: 'unsed', maxWidth: '300px', margin: 'auto'}} />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <h2 className="timer">Escaneie o QRCode para pagar com Pix</h2>
          <p className="timer">
            Seu código expira em:
          </p>
          <p className="timer">
            <img src={ClockIcon} style={{width: '41px', height: '41px', verticalAlign: 'middle'}} />
            <span style={{verticalAlign: 'center', fontSize: 32, color: '#330A8B', fontWeight: 'bold', minWidth: '90px', display: 'inline-block', verticalAlign: 'middle'}}>{timeLeft}</span>
          </p>
          <p className="timer">
            <Button type="primary" size="medium" shape="round" onClick={clipboardQRCode}>
              Copiar código pix
            </Button>
          </p>
        </Col>
        <Col span={24}>
          <div style={{padding: 20}}>
            <p style={{border: '1px solid gray', overflowWrap: 'break-word'}}>
              {order.qr_code_text}
            </p>
            <br/>
            <h3 className="purple">Como pagar com o código</h3>
            <p>
              1 - Clique no botão acima para copiar o código PIX.<br/>
              2 - Abra o seu aplicativo bancário<br/>
              3 - Acesse a área PIX - Pix copia e cola<br/>
              4 - Siga as instruções do aplicativo
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
}