import React from 'react';
import { Button } from 'antd'
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function SmallCampaign({campaign, project}) {
  function campaignUrl() {
    if (campaign['open?']) {
      return `/${project.name}/site/campanhas/${campaign.id}`;
    } else if (moment(campaign.draw_time).isSame(moment(), 'day')) {
      return `/${project.name}/site/acompanhar-sorteio`;
    } else if (moment(campaign.start_time).isAfter(moment())) {
      return '';
    } else {
      return `/${project.name}/site/resultados?campaign_id=${campaign.id}`;
    }
  }
  return (
    <div className="small_campaign">
      <Link to={campaignUrl}>
        <img src={campaign.square_banner_url} alt={campaign.title} />
        <div className="content">
          <h1 style={{ marginBottom: 0}}>
            {campaign.title}<br/>
            <small>{campaign.subtitle}</small>
          </h1>
          <div className="button">
            <Button size="small" shape="round">{campaign.t_user_status}</Button>
          </div>
        </div>
      </Link>
    </div>
  )
}