import React from 'react';
import { Collapse, Button } from 'antd';
import { Link } from 'react-router-dom';
import FaqCollapse from '../../../components/FaqCollapse';

const { Panel } = Collapse;

export default function FaqHomeIndex({frequently_asked_questions, project}) {
  return (
    <div id="home_faq">
      <h1>
        Ficou alguma dúvida?
        <br/>
        <small>Estamos aqui para ajudar</small>
      </h1>
      <div className="smaller_container">
        <FaqCollapse frequently_asked_questions={frequently_asked_questions} />
        <p style={{marginTop: 40, textAlign: 'center'}}>
          <Link to={`/${project.name}/site/faq`}>
            <Button shape="round" size="small" type="default">Ver mais</Button>
          </Link>
        </p>
      </div>
    </div>
  );
}