import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify'
import { UserOutlined } from '@ant-design/icons';
import MaskedInput from '../../components/MaskedInput';
import { Button, Form, Layout, Steps } from 'antd';
import { useHistory } from 'react-router-dom'
import axios from 'axios';
import { AffiliateContext } from '../App.js';
import Swal from 'sweetalert2';


const { Content } = Layout;

export default function AffiliateCpf({isMobile, project}) {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const disabled = loading;
  const { affiliate, setAffiliate } = useContext(AffiliateContext);
  const [ form ] = Form.useForm()
  const history = useHistory();
  
  function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach(function (item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
  }

  function cpf_check(formData) {
    document.activeElement.blur();
    setLoading(true);
    axios.post('/affiliates/cpf/check', { params: { cpf: formData.cpf } })
      .then(function(response){
        if (response.data.exists) {
          handleExistingAccount(response.data);
        } else {
          setAffiliate(response.data);
          history.push(`/${project.name}/site/revendedor/cadastro`);
        }
      })
      .catch(function(error) {
        if (error.response.status == 422){
          setErrors({cpf: [error.response.data.error]});
        } else if (error.response.status == 403){
          toast.error('Você já está logado. Estamos redirecionando você em instantes.');
          setTimeout(() => {
            window.location.href = '/revendedor';
          }, 3000);
        } else {
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');
        }

      })
      .then(function() {
        setLoading(false);
      });
  }

  function handleExistingAccount(data) {
    window.location.href = `/revendedor/login?cpf=${data.cpf}`;
  }

  return (
    <>
      <Content className="smallest_container">
        <h1>
          Seja um revendedor Nacional CAP!
        </h1>
        <p className="center">
          <b>Chegou a hora de você aumentar sua renda.</b><br/>
          Venda nacional Cap de casa ou de onde estiver.<br/>
          Preencha o formulário e comece a vender.<br/>
          Não perca essa chance!
        </p>
        <p className="center">
          Precisamos identificá-lo para prosseguirmos.
        </p>
        <Form form={form} layout="vertical" name="cpf_check" className="login-form" initialValues={{ remember: true }} onFinish={cpf_check}>
          <Form.Item name="cpf" label="CPF" required hasFeedback={!!errors['cpf']} validateStatus={errors['cpf'] && 'error'} help={errors['cpf']?.join(', ')}>
            <MaskedInput name="cpf" type="tel" inputmode="tel" pattern="[0-9]*" form={form} prefix={<UserOutlined className="site-form-item-icon" />}  mask="999.999.999-99" placeholder="000.000.000-00" />
          </Form.Item>
          <Form.Item>
            <Button disabled={disabled} shape="round" block type="primary" htmlType="submit" loading={loading} className="login-form-button">
              Continuar
            </Button>
          </Form.Item>
        </Form>
      </Content>
    </>
  );
}