import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom';
import YoutubeVideo from '../../components/YoutubeVideo';
import axios from 'axios';
import { toast } from 'react-toastify';

const rootStyle = {
  padding: '50px 0',
  textAlign: 'center',
};

export default function Testimonials({project}) {
  const [winners, setWinners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    loadWinners();
  }, []);

  function loadWinners() {
    axios.get(`/api/int/v1/home/${project.name}/testimonials`)
      .then(function(response){
        setWinners(response.data.testimonials);
        setHasMore(response.data.next_page);
      })
      .catch(function(error){
        toast.error('Erro ao carregar ganhadores');
      })
      .then(function() {
        setLoading(false);
      });
  }


  return (
    <div style={rootStyle}>
      <div className="container">
        <h1>Conheça os nossos ganhadores</h1>
        <div>
          <Row gutter={[16, 16]}>
            {
              winners.map(winner => (
                <Col xs={24} sm={24} md={8} lg={8} xl={8} key={winner.id}>
                  <YoutubeVideo videoId={winner.video_id} />
                </Col>
              ))
            }
          </Row>
          {
            hasMore &&
              <p style={{marginTop: 40}}>
                <Link to={`/${project.name}/site/depoimentos`}>
                  <Button shape="round" size="small" type="primary">Ver mais</Button>
                </Link>
              </p>
          }
          <h1>Você pode ser o próximo!</h1>
        </div>
      </div>
    </div>
    
  );
}